<template>
  <v-row align-content="center" class="">
    <v-col class="py-0 mx-auto " md="12">
      <!-- <v-row v-if="error">
        <v-col style="height: 100vh">
          <v-spacer class="py-8"></v-spacer>
          <transition name="fade">
            <div class="px-8">
              <v-alert type="error">Link is invalid</v-alert>
            </div>
          </transition>
        </v-col>
      </v-row> -->
      <!-- <v-row v-else> -->
      <v-row>
        <v-col class="white mx-auto" md="6" style="height: 100vh">
          <v-spacer class="py-8"></v-spacer>
          <transition name="fade">
            <v-form class="px-8" @submit.prevent="submit" ref="form" lazy-validation v-model="valid">
              <div class="fs-32 font-weight-bold mb-4">Password Baru</div>
              <div class="fs-18 color-disable mb-3">
                Password harus mengandung sedikitnya 7 huruf dan 1 angka
              </div>
              <v-alert type="error" text dense prominent v-show="response !== null">{{ response }}</v-alert>
              <v-row class="pb-0">
                <v-col>
                  <div class="mb-2">
                    <span class="font-weight-medium">Password</span>
                  </div>
                  <v-text-field v-model="password" placeholder="Masukkan password baru anda" :rules="pswRules" required
                    outlined hide-details="auto" class="rounded-xl" :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'" @click:append="show = !show"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="mb-2">
                    <span class="font-weight-medium">Konfirmasi Password</span>
                  </div>
                  <v-text-field class="rounded-xl" v-model="confirm_password" placeholder="Masukkan ulang password anda"
                    :rules="cnfrmPswRules" outlined hide-details="auto" required :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                    @click:append="show2 = !show2"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn large elevation="0" type="submit" color="primary" :loading="submiting" block class="rounded-xl"
                    @click="resetPsw()">
                    Reset password
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <!-- <div v-else class="px-8">
              <div class="fs-32 font-weight-bold mb-4">Hello</div>
              <div class="fs-18 color-disable mb-2">
                Permohonan memperbarui password telah dibuat. Jika anda yang
                membuat permohonan ini, harap klik tombol di bawah ini.
              </div>
              <v-spacer class="pb-2"></v-spacer>
              <v-row>
                <v-col>
                  <v-btn
                    class="rounded-xl"
                    large
                    elevation="0"
                    color="primary"
                    :loading="submiting"
                    block
                    @click="show = true"
                  >
                    Reset password
                  </v-btn>
                </v-col>
              </v-row>
            </div> -->
          </transition>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "password-reset",
  data() {
    return {
      show: false,
      show2: false,
      error: false,
      response: null,
      errors: [],
      valid: true,
      password: "",
      confirm_password: "",
      submiting: false,
      pswRules: [
        (v) => !!v || "Password tidak boleh kosong",
        (v) => (v && v.length >= 8) || "Password harus minimal 8 karakter",
      ],
    };
  },
  computed: {
    cnfrmPswRules() {
      return [
        () =>
          this.password === this.confirm_password ||
          "Konfirmasi password tidak sama",
        (v) => !!v || "Konfirmasi Password tidak boleh kosong",
      ];
    },
  },
  created() {
  },
  methods: {
    submit() {

    },
    hasError(input) {
      return this._.has(this.errors, input);
    },
    resetPsw() {
      // this.$router.push({ name: 'success-reset-password'})
      this.submiting = true;
      if (this.$refs.form.validate()) {
        let data = { password: this.password, confirm_password: this.confirm_password };
        let token = `Bearer ${this.$route.params.id}`;
        // `/api/v1/reset/password`,
        this.axios
          .put(`/auth/v1/member/updated-password`, data, {
            headers: {
              "Authorization": token,
            },
          })
          .then(() => {
            this.submiting = false;
            // this.$router.push("/auth/success-reset-password");
            this.$router.push({ name: 'success-reset-password' })
          })
          .catch((res) => {
            this.submiting = false;
            this.$refs.form.reset();
            console.log(res.data)
            this.response = "Reset password gagal";
            // console.error(error);
          });
      } else {
        this.submiting = false;
      }
    }
  },
};
</script>

<style></style>