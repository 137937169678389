<template>
	<div>
		<div class="font-weight-bold mb-3">{{ act.title }}</div>
		<v-row :class="$vuetify.breakpoint.name == 'xs' ? 'mx-2' : ''">
			<v-col sm="12" v-if="act.is_complete === true && !show_re_upload">
				<v-card elevation="0" style="min-height: 60vh; width: 100%" class="col-md-12">
					<br /><br /><br /><br />
					<div class="pa-3 text-center">
						<img src="@/assets/images/success.png" class="text-center mb-2" /><br />
						<h3 class="font-weight-bold">
							File berhasil diunggah
						</h3>
						<p class="">
							File hasil pengerjaan tugas praktik mandiri telah berhasil diunggah
						</p>
						<template v-if="act.upload.note.score">
							<div class="my-3">
								<div class="text-center font-weight-bold">Nilai : </div>
								<div class="text-center primary--text">
									<span class="text-h3 font-weight-bold">
										{{ act.upload.note.score }}
									</span>
								</div>
							</div>
						</template>
						<template v-if="act.upload.note.text">
							<h4 class="font-weight-bold">
								Balasan :
							</h4>
							<p v-html="textUrlify(act.upload.note.text)"></p>
						</template>
						<div v-if="act.is_complete === true && act.end_test_at !== null"
							:class="$vuetify.breakpoint.name == 'xs' ? 'd-flex-col justify-center align-center' : 'd-flex justify-center align-center'"
							style="gap: 15px">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn depressed v-on="on" width="200" :class="$vuetify.breakpoint.name == 'xs' ? 'mb-3' : ''"
										v-bind="attrs" :loading="btn_loading" @click="resendFileUpload" color="primary">
										Submit Ulang Tugas
									</v-btn>
								</template>
								<span>Mengirim ulang tugas yang pernah di upload</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn depressed v-on="on" width="200" v-bind="attrs" :loading="btn_loading"
										@click="show_re_upload = true" outlined color="primary">
										Upload file baru
									</v-btn>
								</template>
								<span>Mengupload ulang file tugas Evaluasi Praktek Mandiri</span>
							</v-tooltip>
						</div>
						<v-btn depressed v-if="act.is_complete == true && act.end_test_at === null" class="mx-2 rounded-lg"
							color="primary" @click="getUnlock()" :loading="btn_loading">
							Klik aktivitas selanjutnya
						</v-btn>
					</div>
				</v-card>
			</v-col>
			<v-col sm="12" v-if="act.is_complete === false || show_re_upload">
				<v-card elevation="0" style="min-height: 60vh; width: 100%" class="col-md-12">
					<div class="pa-3">
						<div class="mb-2 pt-8" v-html="act.theory.description"></div>
						<div class="drop display-inline text-center" @dragover.prevent @drop="onDrop">
							<div class="content-img pt-12" v-if="!image">
								<p class="mb-2 pt-3">
									<img src="@/assets/images/input-file.png" class="text-center mb-2" />
								</p>
								<p class="mb-2 subtitle-2"></p>
								<!-- v-show="!showButtonUpload && act.can_upload" -->
								<label v-if="!showButtonUpload" class="btn-img display-inline text-center rounded-lg">
									Pilih Berkas
									<input type="file" name="image"
										accept="image/png,application/pdf,video/mp4,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.doc,.docx"
										@change="onChange" />
								</label>
								<label v-if="showButtonUpload && show_re_upload" class="btn-img display-inline text-center rounded-lg">
									Pilih Berkas
									<input type="file" name="image"
										accept="image/png,application/pdf,video/mp4,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.doc,.docx"
										@change="onChange" />
								</label>
								<v-alert v-if="!showButtonUpload" type="info" text outlined class="col-md-11 mx-auto mt-5" dense>
									Format file yang didukung adalah png, pdf , docx, xlsx dan mp4. Ukuran maks. 20 MB.
									<div v-if="act.max_upload_duration_at != null">Batas akhir pengumpulan pada <strong>{{
										convertMaxUpload
											}}</strong></div>
								</v-alert>
								<v-alert v-if="showButtonUpload" type="warning" text outlined class="col-md-11 mx-auto mt-5" dense>
									Waktu Pengumpulan berkas sudah melewati batas akhir.
									<div>Batas akhir pengumpulan pada <strong>{{ convertMaxUpload }}</strong></div>
								</v-alert>
							</div>
							<div class="hidden display-inline align-center py-4" v-bind:class="{ image: true }" v-else>
								<div class="box-img-line " style="width: 100%;margin:auto;position: relative;">
									<object :data="image" class="img-cover" />
									<div class="btn-float">
										<label class="btn bg-label mx-2" elevation="1">
											<v-icon>
												ri-arrow-go-back-line
											</v-icon>
											<input type="file" name="image" @change="onChange" accept="image/png,application/pdf,video/mp4" />
										</label>
										<v-btn small class="mx-2 fab-white" fab @click="removeFile">
											<v-icon dark>
												ri-delete-bin-7-line
											</v-icon>
										</v-btn>
									</div>
								</div>
								<v-btn depressed class="mx-2 rounded-lg" color="primary" :loading="btn_loading" @click="submitUpload()">
									Upload File
								</v-btn>
							</div>
						</div>
					</div>
				</v-card>
			</v-col>
			<v-snackbar v-model="notification.state" top color="primary" outlined>
				{{ notification.message }}
				<template v-slot:action="{ attrs }">
					<v-btn color="primary" icon v-bind="attrs" @click="notification.state = false">
						<v-icon>$close</v-icon>
					</v-btn>
				</template>
			</v-snackbar>
			<DialogLoad ref="dialogLoad"></DialogLoad>
			<DialogUploadSuccess ref="dialogUploadSuccess" @getActFalse="getActFalse()"></DialogUploadSuccess>
		</v-row>
	</div>
</template>

<script>
import moment from "moment";
import DialogLoad from "../dialog/DialogLoad";
import DialogUploadSuccess from "../dialog/DialogUploadSuccess";

export default {
	components: {
		DialogLoad, DialogUploadSuccess,
	},
	props: {
		act: Object,
		detail_class: Object,
		detail_topic: Object,
		id_topic: String,
		id_act: String,
	},
	data() {
		return {
			btn_loading: false,
			show_re_upload: false,
			file: "",
			image: null,
			notification: {
				state: false,
				message: '',
			},
		}
	},
	computed: {
		showButtonUpload() {
			let max_upload = this.act.max_upload_duration_at
			let date_now = new Date()
			let max_upload_duration = moment(max_upload).format('YYYY-MM-DD HH:mm:ss')
			let date_now_duration = moment(date_now).format('YYYY-MM-DD HH:mm:ss')
			let state = false

			if (date_now_duration > max_upload_duration && this.act.can_upload) {
				state = true
			}
			return state
		},
		convertMaxUpload() {
			return moment.utc(this.act.max_upload_duration_at).locale("id").format('DD MMMM YYYY HH:mm:ss')
		}
	},
	created() {
		if (this.act.has_done_this_activity) {
			this.$refs.dialogUploadSuccess.dialogShow()
		}
	},
	methods: {
		async getHasDoneActivity() {
			const data = {
				member_redeem_id: this.$route.params.id,
				topic_id: this.id_topic,
				activity_id: this.id_act,
			};
			await this.axios.post(`/users/v1/member/activity/update-has-done`, data)
				.then(() => {
					this.btn_loading = false
					this.$emit('getDetailClass')
				}).catch(() => {
					this.btn_loading = false
				});
		},
		textUrlify(text) {
			let urlRegex = /(https?:\/\/[^\s]+)/g
			return text.replace(urlRegex, function (url) {
				return '<a href="' + url + '" target="_blank">' + url + '</a>';
			})
		},
		onChange(e) {
			const files = e.target.files[0];
			this.image = URL.createObjectURL(files);
			this.file = e.target.files[0];
		},
		onDrop: function (e) {
			e.stopPropagation();
			e.preventDefault();
			const files = e.dataTransfer.files;
			this.file = files[0]
			this.createFile(files[0]);
		},
		createFile(file) {
			const reader = new FileReader();
			const vm = this;

			reader.onload = function (e) {
				vm.image = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		removeFile() {
			this.image = "";
		},
		async resendFileUpload() {
			this.btn_loading = true
			let formData = new FormData();
			formData.append("member_redeem_id", this.detail_class.id);
			formData.append("topic_id", this.id_topic);
			formData.append("activity_id", this.id_act);

			await this.axios.post(`/users/v1/myprogram/re-upload`, formData).then(() => {
				this.btn_loading = false
				this.notification = {
					state: true,
					message: 'File berhasil di kirim ulang',
				};
			}).catch((error) => {
				let message = error.response ? error.response.data.message : error.message;
				this.btn_loading = false
				this.notification = {
					state: true,
					message: message ? message : 'Gagal mengirim ulang file',
				};
			});
		},
		submitUpload() {
			this.$refs.dialogLoad.dialogShow()
			this.btn_loading = true;

			let formData = new FormData();
			formData.append("member_redeem_id", this.detail_class.id);
			formData.append("file", this.file);
			formData.append("topic_id", this.id_topic);
			formData.append("activity_id", this.id_act);
			this.axios
				.post("/users/v1/myprogram/upload_activity", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then(() => {
					this.$refs.dialogLoad.dialogHide()
					this.btn_loading = false;
					this.$refs.dialogUploadSuccess.dialogShow()

					this.show_re_upload = false

					// Check apakah aktivitas ini sudah selesai atau belum
					if (!this.act.has_done_this_activity) {
						this.getHasDoneActivity()
					}

					this.image = "";
				})
				.catch((error) => {
					this.$refs.dialogLoad.dialogHide()
					this.btn_loading = false;
					let message = error.response ? error.response.data.message : error.message;
					this.notification = {
						state: true,
						message,
					};
				})
		},
		getUnlock() {
			let next_topic_at = this.detail_topic.next_topic_at
			let date_now = new Date()
			let next_topic_date = moment.utc(next_topic_at).format('YYYY-MM-DD HH:mm:ss')
			let date_now_at = moment.utc(date_now).format('YYYY-MM-DD HH:mm:ss')
			let next_topic_date_res = moment.utc(next_topic_at).locale("id").format('DD MMMM YYYY HH:mm:ss')

			// Check apakah aktivitas ini sudah selesai atau belum
			if (!this.act.has_done_this_activity) {
				this.getHasDoneActivity()
			}

			// check if next topic is under next topci at
			if (next_topic_at != null && date_now_at < next_topic_date) {
				// Chek if this activity is last in topic
				if (this.act.is_last_activity) {
					return this.notification = {
						state: true,
						message: `Mohon Maaf, Topik selanjutnya dapat di akses pada <b class="font-weight-bold">${next_topic_date_res}</b>`,
					};
				}
			}


			this.btn_loading = true
			const data = {
				program_id: this.$route.params.id,
				topic_id: this.id_topic,
				activity_id: this.id_act,
			};
			this.axios
				.post(`/users/v1/member/activity/unlock`, data)
				.then(async (res) => {
					this.btn_loading = false
					this.notification = {
						state: true,
						message: res.data.message,
					};
					this.$emit('getDetailClass')
					this.$emit('getActFalse', this.id_topic, this.id_act)
				})
				.catch((error) => {
					this.btn_loading = false
					this.notification = {
						state: true,
						message: error.response.data.message,
					};
				});
		},
		getActFalse() {
			this.$emit('getActFalse', this.id_topic, this.id_act)
		},
	},
}
</script>