<template>
  <v-row>
    <v-container class="pt-0" :style="{
      maxWidth: !$vuetify.breakpoint.smAndUp ? 'unset !important' : '',
    }">
      <v-row v-if="loadFirstContent" class="fill-height" align-content="center" justify="center" style="height: 60vh">
        <v-col class="text-center text-subtitle-1 color-navy-soft" cols="12">
          Tunggu beberapa saat ...
        </v-col>
        <v-col cols="6">
          <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" sm="12">
          <v-row no-gutters>
            <v-btn class="pl-0 pr-2 mt-4" outlined color="primary" @click="$router.push('/kelas')">
              <v-icon>$prev</v-icon>
              Kelas saya
            </v-btn>
            <!-- <template v-if="$vuetify.breakpoint.smAndUp">
              <div v-if="program.type === 'hybrid' || program.type === 'offline'" class="pl-4 ml-auto col-md-4">
                <v-card class="elevation-0" @click="showScan = true" style="cursor: pointer">
                  <v-card-actions>
                    <v-btn text>
                      <img src="@/assets/images/barcode.svg" width="18" class="mr-2" />
                      QR Code Absen
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon>
                      <v-icon class="">ri-arrow-right-line</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </template> -->
            <v-spacer v-if="!$vuetify.breakpoint.smAndUp"></v-spacer>
            <v-btn v-if="!$vuetify.breakpoint.smAndUp" rounded small color="teal" text class="px-2 mt-4 white"
              @click="showActivity = true">
              <div class="d-flex">
                <v-icon small class="mr-1 d-block">ri-lightbulb-line</v-icon>
                Aktivitas
              </div>
            </v-btn>
          </v-row>
        </v-col>

        <!-- SECTION PROGRAM NAME -->
        <v-col cols="12">
          <p text height="32" class="my-0 pa-0 font-weight-bold text-capitalize primary" @click="
            act = {};
          act_active = null;
          topics_active = null;
          " style="
              cursor: pointer;
              text-decoration: none;
              font-size: 20px;
              padding: 25px 20px !important;
              color: #fff;
              border-radius: 4px;
            ">
            {{ program.title }}
          </p>
        </v-col>

        <!-- SECTION PROGRAM CONTENT -->
        <v-col cols="12" sm="8" class="mb-3">
          <v-row v-if="loadContent" class="fill-height" align-content="center" justify="center" style="height: 60vh">
            <v-col class="text-center text-subtitle-1 color-navy-soft" cols="12">
              Tunggu beberapa saat ...
            </v-col>
            <v-col cols="6">
              <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
          </v-row>
          <template v-else>
            <v-tabs v-if="actType" v-model="contentType" :right="$vuetify.breakpoint.name == 'xs' &&
              act.settings.discussion != null
              " class="mb-4" background-color="transparent">
              <v-tabs-slider></v-tabs-slider>
              <v-tab>
                <v-icon>ri-survey-line</v-icon>
                <span class="ml-2" style="text-transform: none">{{
                  actType
                }}</span>
              </v-tab>
              <v-tab v-show="act.settings.discussion == 'public'">
                <v-icon>ri-question-answer-line</v-icon>
                <span class="ml-2" style="text-transform: none">Diskusi Publik</span>
              </v-tab>
              <v-tab v-show="act.settings.discussion == 'private'">
                <v-icon>ri-message-3-line</v-icon>
                <span class="ml-2" style="text-transform: none">Diskusi Private</span>
              </v-tab>
            </v-tabs>

            <template v-if="contentType === 0">
              <template v-if="act.type == 'menonton_video'">
                <act-menonton-video :act="act" @ended="ended(act.is_complete)" />
              </template>
              <template v-else-if="act.type == 'embed_youtube'">
                <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
                  <div class="pl-4 mb-4 font-weight-bold primary-text text-h5">{{ act.title }}</div>
                  <v-col cols="12" class="pa-0">
                    <v-card v-for="(link, index) in act.theory.embed_youtube" :key="'A' + index" elevation="0">
                      <v-responsive :aspect-ratio="16 / 9">
                        <div style="height:500px;position:relative;overflow:hidden;pointer-events: none;">
                          <iframe width="420" height="345"
                            :src="`${link.url}&autoplay=1&loop=1&color=white&controls=1&disablekb=1&modestbranding=1&playsinline=1&rel=1&enablejsapi=1`"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            tabindex="-1" allowfullscreen>
                          </iframe>
                        </div>
                        <!-- <iframe width="420" height="345"
                        :src="`${link.url}?theme=dark&autoplay=1&autohide=0&cc_load_policy=1&modestbranding=1&fs=0&showinfo=0&rel=0&iv_load_policy=3&mute=0&loop=1`" 
                        sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"></iframe> -->
                      </v-responsive>
                    </v-card>
                  </v-col>
                  <v-card elevation="0" class="col-12">
                    <div id="desc-video" class="pa-4">
                      <p class="mb-4 text-justify text-body-2" v-html="act.theory.description"></p>
                    </div>
                  </v-card>
                </v-row>
              </template>
              <template v-else-if="act.type == 'pre_test'">
                <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
                  <div class="mb-3 font-weight-bold text-capitalize ms-3 text-h6">
                    {{ act.title }}
                  </div>

                  <v-card v-if="act.is_complete == true || act.repeat_test > 0" class="col-md-12" elevation="0">
                    <div v-if="resultNilai" class="pa-4">
                      <v-row class="py-4" justify="center">
                        <v-col cols="8" class="justify-center d-flex">
                          <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                        </v-col>

                        <v-col cols="8" class="d-flex justify-space-between">
                          <div>Kesempatan mengulang</div>
                          <div v-if="act.settings.opportunity_repeat_test === -1">
                            Tanpa Batas
                          </div>
                          <div v-else>
                            <template v-if="
                              parseInt(
                                act.settings.opportunity_repeat_test -
                                act.repeat_test
                              ) < 0
                            ">
                              0x
                            </template>
                            <template v-else>
                              {{
                                act.settings.opportunity_repeat_test -
                                act.repeat_test
                              }}x
                            </template>
                          </div>
                        </v-col>
                        <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8"
                          class="d-flex justify-space-between">
                          <div>Nilai minimal</div>
                          <div>
                            {{ act.settings.set_minimum_value.minimum_value }}
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col v-if="act.settings.is_show_score === true" cols="8">
                          <div class="text-center font-weight-bold">Nilai</div>
                          <div class="text-center primary--text">
                            <span class="text-h3 font-weight-bold">
                              {{ act.score }}
                            </span>
                            <span class="text-h4 font-weight-bold" v-if="act.settings.set_minimum_value.is_set">/{{
                              act.settings.set_minimum_value.minimum_value }}</span>
                          </div>
                        </v-col>
                        <v-col md="6" sm="12">
                          <v-btn v-if="act.settings.is_show_discussion" rounded block color="primary" elevation="0"
                            large @click="
                              showExplanationPre = true;
                            resultNilai = false;
                            getExplan(act.settings.is_show_answer);
                            ">Lihat Jawaban</v-btn>
                          <v-spacer class="py-2"></v-spacer>
                          <div v-if="
                            act.settings.opportunity_repeat_test === 0 ||
                            act.settings.opportunity_repeat_test -
                            act.repeat_test ===
                            0
                          "></div>
                          <div v-else>
                            <v-btn rounded block text color="primary" elevation="0" large
                              @click="redirect(act.type, act.settings)">
                              <span>Ulangi Pre Test</span>
                            </v-btn>
                          </div>
                          <v-spacer class="py-2"></v-spacer>
                          <v-btn v-if="isHiddenButtonNextActivity" color="primary" depressed rounded block large
                            @click="getUnlockUpload()" :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="showQues" class="pa-4">
                      <div class="px-4 text-right" v-if="!activity_loading && currentQuestion.test.timer.active">
                        <v-btn depressed x-small dark class="rounded-lg" color="red">
                          Pengerjaan Soal : {{ totalSecondsQuestion | minutesAndSeconds }}
                        </v-btn>
                      </div>
                      <div class="pa-4 row align-center navy-soft--text">
                        <div id="box-navigasi"
                          :class="$vuetify.breakpoint.name == 'xs' ? 'col-md-4 d-flex flex-colmun align-center' : 'col-md-4 d-flex'"
                          style="gap: 8px">
                          <v-btn depressed small color="#EBF5FF" class="mr-1" @click="goNumber(act.current - 1)"
                            :disabled="act.current <= 1 || !act.settings.is_skip"
                            style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$prev</v-icon></v-btn>
                          <div class="mt-2 mr-1 text-center">
                            Soal no
                            <span class="primary--text">{{ act.current }}</span>
                            dari
                            {{ act.total_question }}
                          </div>
                          <v-btn v-if="act.settings.is_skip === false" small depressed color="#EBF5FF"
                            :disabled="isRequired" @click="goNumber(act.current + 1)"
                            style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                          <v-btn v-else depressed color="#EBF5FF" small :disabled="act.current ===
                            act.total_question
                            " @click="goNumber(act.current + 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                        </div>
                        <div class="col-md-4">
                          <div v-if="act.settings.is_show_timer === true"
                            class="mb-0 text-center fs-16 pa-2 color-black" color="primary">
                            <v-icon left>mdi-clock-time-three-outline</v-icon>
                            Sisa Waktu Berlangsung: {{ totalSeconds | minutesAndSeconds }}
                          </div>
                        </div>
                        <div class="justify-end col-md-4 align-end text-end">
                          <v-tooltip color="grey darken-3" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :disabled="!review && act.current !== act.total_question" depressed
                                :block="$vuetify.breakpoint.name == 'xs'" v-bind="attrs" v-on="on" class="rounded-lg"
                                color="primary" small @click="submitReview(act.settings)">
                                Submit
                              </v-btn>
                            </template>
                            <span>Submit</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="user-select: none">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestion.test.question.text"></div>
                          <div v-if="currentQuestion.test.question.media != ''">
                            <audio-player class="d-inline-block" :src="currentQuestion.test.question.media[0]
                              .public_url
                              " :repeat_audio="act.settings.opportunity_repeat_audio" />
                          </div>
                          <v-radio-group :disabled="currentQuestion.test.timer.active && totalSecondsQuestion == 0 && !act.settings.is_skip"
                            hide-details="auto" v-model="currentQuestion.answer" class="options">
                            <v-radio v-for="(item, index) in optionQuestion" :key="index" class="mb-2" :value="index">
                              <template v-slot:label>
                                <div style="display: block">
                                  <div v-html="item.text"></div>
                                  <img width="250px" height="auto" class="mt-1 rounded-lg"
                                    :src="item.media[0].public_url" v-if="item.media.length > 0" />
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                          <div v-if="review">
                            <div class="font-weight-bold">Pembahasan soal:</div>
                            <v-list class="options" disabled>
                              <v-list-item-group class="snow" v-model="correct_answer" color="primary">
                                <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" v-for="item in 1"
                                  :key="item">
                                  <v-list-item-content>
                                    <div class="mb-4 green--text">
                                      Jawaban yang benar adalah
                                      {{ "Opsi " + item }}
                                    </div>
                                    <div class="navy-soft--text">
                                      In feugiat id nec habitant tortor.
                                      Faucibus montes.
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="showExplanationPre" class="pa-4">
                      <div class="pa-4 row align-center navy-soft--text">
                        <div id="box-navigasi" class="col-md-6 d-flex" style="gap: 8px">
                          <v-btn depressed small color="#EBF5FF" class="mr-1" @click="goNumber(act.current - 1)"
                            :disabled="act.current <= 1" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$prev</v-icon></v-btn>
                          <div class="mt-2 mr-1 text-center">
                            Soal no
                            <span class="primary--text">{{ act.current }}</span>
                            dari
                            {{ act.total_question }}
                          </div>
                          <v-btn depressed color="#EBF5FF" small :disabled="act.current ===
                            act.total_question
                            " @click="goNumber(act.current + 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                        </div>
                        <div class="justify-end col-md-6 align-end text-end">
                          <v-tooltip v-if="!review" color="grey darken-3" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" small depressed :block="$vuetify.breakpoint.name === 'xs'"
                                color="primary" @click="
                                  resultNilai = true;
                                showExplanationPre = false;
                                act.current = 1;">
                                Kembali
                              </v-btn>
                            </template>
                            <span>Kembali</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="user-select: none">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text" />

                          <v-list v-if="
                            act.settings.is_show_discussion === true &&
                            act.settings.is_show_answer === false
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow" color="primary">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="review
                                ? item == 2
                                  ? 'green'
                                  : item == 4
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestion" :key="index">
                                <v-list-item-content>
                                  {{ item.text }}
                                </v-list-item-content>
                                <v-list-item-icon v-if="review && [2, 4].includes(item)">
                                  <v-icon>
                                    {{
                                      item == 2
                                        ? "ri-checkbox-circle-fill"
                                        : "ri-close-circle-fill"
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-list v-else-if="
                            act.settings.is_show_discussion === true &&
                            act.settings.is_show_answer === true
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="answerExplan
                                ? answerExplan == true
                                  ? 'green'
                                  : answerExplan == false
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content :class="(item.text === answerTextExplan &&
                                  answerExplan === true &&
                                  correctAnswer === true) ||
                                  (item.text === correctTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false)
                                  ? 'success--text font-weight-bold'
                                  : item.text === answerTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false
                                    ? 'error--text font-weight-bold'
                                    : ''
                                  ">
                                  <div v-html="item.text" />
                                </v-list-item-content>
                                <v-list-item-icon>
                                  <v-icon>
                                    {{
                                      (item.text === answerTextExplan &&
                                        answerExplan === true &&
                                        correctAnswer === true) ||
                                        (item.text === correctTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false)
                                        ? "ri-checkbox-circle-fill success--text"
                                        : item.text === answerTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false
                                          ? "ri-close-circle-fill error--text"
                                          : ""
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-alert shaped dense prominent icon="ri-spam-2-fill" type="error"
                            v-show="currentQuestion.answer.length == 0">
                            Soal ini tidak dijawab.
                          </v-alert>

                          <div>
                            <div class="font-weight-bold">Pembahasan soal:</div>
                            <v-list class="options" disabled>
                              <v-list-item-group class="snow" color="primary">
                                <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded">
                                  <v-list-item-content>
                                    <div class="navy-soft--text" v-html="explanation.text" />
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                  <v-card v-else class="col-12" elevation="0">
                    <div v-if="resultNilaiFalse" class="pa-4">
                      <v-row class="py-4" justify="center">
                        <v-col cols="8" class="justify-center d-flex">
                          <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                        </v-col>

                        <v-col cols="8" class="d-flex justify-space-between">
                          <div>Kesempatan mengulang</div>
                          <div v-if="act.settings.opportunity_repeat_test === -1">
                            Tanpa Batas
                          </div>
                          <div v-else>
                            <template v-if="
                              parseInt(
                                act.settings.opportunity_repeat_test -
                                act.repeat_test
                              ) < 0
                            ">
                              0x
                            </template>
                            <template v-else>
                              {{
                                act.settings.opportunity_repeat_test -
                                act.repeat_test
                              }}x
                            </template>
                          </div>
                        </v-col>
                        <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8"
                          class="d-flex justify-space-between">
                          <div>Nilai minimal</div>
                          <div>
                            {{ act.settings.set_minimum_value.minimum_value }}
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col v-if="act.settings.is_show_score === true" cols="8">
                          <div class="text-center font-weight-bold">Nilai</div>
                          <div class="text-center primary--text">
                            <span class="text-h3 font-weight-bold">
                              {{ act.score }}
                            </span>
                            <span class="text-h4 font-weight-bold" v-if="act.settings.set_minimum_value.is_set">/{{
                              act.settings.set_minimum_value.minimum_value }}</span>
                          </div>
                        </v-col>
                        <v-col md="6" sm="12">
                          <v-btn v-if="act.settings.is_show_discussion" rounded block color="primary" elevation="0"
                            large @click="
                              showExplanationPreFalse = true;
                            resultNilaiFalse = false;
                            getExplan(act.settings.is_show_answer);
                            ">Lihat Jawaban</v-btn>
                          <v-spacer class="py-2"></v-spacer>
                          <div v-if="
                            act.settings.opportunity_repeat_test === 0 ||
                            act.settings.opportunity_repeat_test -
                            act.repeat_test ===
                            0
                          "></div>
                          <div v-else>
                            <v-btn rounded block text color="primary" elevation="0" large
                              @click="redirectFalse(act.type, act.settings)">
                              <span>Ulangi Pre Test</span>
                            </v-btn>
                          </div>
                          <v-spacer class="py-2"></v-spacer>
                          <v-btn v-if="isHiddenButtonNextActivity" color="primary" depressed rounded block large
                            @click="getUnlockUpload()" :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="showQuesFalse" class="pa-4">
                      <div class="px-4 text-right" v-if="!activity_loading && currentQuestion.test.timer.active">
                        <v-btn depressed x-small dark class="rounded-lg" color="red">
                          Pengerjaan Soal : {{ totalSecondsQuestion | minutesAndSeconds }}
                        </v-btn>
                      </div>
                      <div class="pa-4 row align-center navy-soft--text">
                        <div id="box-navigasi" class="col-md-4 d-flex" style="gap: 8px">
                          <v-btn depressed small color="#EBF5FF" class="mr-1" @click="goNumber(act.current - 1)"
                            :disabled="act.current <= 1 || !act.settings.is_skip"
                            style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$prev</v-icon></v-btn>
                          <div class="mt-2 mr-1 text-center">
                            Soal no
                            <span class="primary--text">{{ act.current }}</span>
                            dari
                            {{ act.total_question }}
                          </div>
                          <v-btn v-if="!act.settings.is_skip" small depressed color="#EBF5FF" :disabled="isRequired"
                            @click="goNumber(act.current + 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                          <v-btn v-else depressed color="#EBF5FF" small :disabled="act.current ===
                            act.total_question
                            " @click="goNumber(act.current + 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                        </div>
                        <div class="col-md-4">
                          <div v-if="act.settings.is_show_timer === true"
                            class="mb-0 text-center fs-16 pa-2 color-black" color="primary">
                            <v-icon left>mdi-clock-time-three-outline</v-icon>
                            Sisa Waktu Berlangsung: {{ totalSeconds | minutesAndSeconds }}
                          </div>
                          <p v-else></p>
                        </div>
                        <div class="justify-end col-md-4 align-end text-end">
                          <v-tooltip color="grey darken-3" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :disabled="!review && act.current !== act.total_question" depressed
                                :block="$vuetify.breakpoint.name === 'xs'" v-bind="attrs" v-on="on" class="rounded-lg"
                                color="primary" small @click="submitReviewFalse(act.settings)">
                                Submit
                              </v-btn>
                            </template>
                            <span>Submit</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="user-select: none">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestion.test.question.text"></div>
                          <div v-if="currentQuestion.test.question.media != ''">
                            <audio-player class="d-inline-block" :src="currentQuestion.test.question.media[0]
                              .public_url
                              " :repeat_audio="act.settings.opportunity_repeat_audio" />
                          </div>
                          <v-radio-group :disabled="currentQuestion.test.timer.active && totalSecondsQuestion == 0 && !act.settings.is_skip"
                            hide-details="auto" v-model="currentQuestion.answer" class="options">
                            <v-radio v-for="(item, index) in optionQuestion" :key="index" class="mb-2" :value="index">
                              <template v-slot:label>
                                <div style="display: block">
                                  <div v-html="item.text"></div>
                                  <img width="250px" height="auto" class="mt-1 rounded-lg"
                                    :src="item.media[0].public_url" v-if="item.media.length > 0" />
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                    </div>
                    <div v-if="showExplanationPreFalse" class="pa-4">
                      <div class="pa-4 row align-center navy-soft--text">
                        <div id="box-navigasi" class="col-md-4 d-flex" style="gap: 8px">
                          <v-btn depressed small color="#EBF5FF" class="mr-1" @click="goNumber(act.current - 1)"
                            :disabled="act.current <= 1" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$prev</v-icon></v-btn>
                          <div class="mt-2 mr-1 text-center">
                            Soal no
                            <span class="primary--text">{{ act.current }}</span>
                            dari
                            {{ act.total_question }}
                          </div>
                          <v-btn depressed color="#EBF5FF" small :disabled="act.current ===
                            act.total_question
                            " @click="goNumber(act.current + 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                        </div>
                        <div class="justify-end col-md-4 align-end text-end">
                          <v-tooltip v-if="!review" color="grey darken-3" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" small depressed :block="$vuetify.breakpoint.name === 'xs'"
                                color="primary" @click="
                                  resultNilai = true;
                                showExplanationPreFalse = false;
                                act.current = 1;">
                                Kembali
                              </v-btn>
                            </template>
                            <span>Kembali</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="user-select: none">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text" />

                          <v-list v-if="
                            act.settings.is_show_discussion === true &&
                            act.settings.is_show_answer === false
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow" color="primary">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="review
                                ? item == 2
                                  ? 'green'
                                  : item == 4
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestion" :key="index">
                                <v-list-item-content>
                                  {{ item.text }}
                                </v-list-item-content>
                                <v-list-item-icon v-if="review && [2, 4].includes(item)">
                                  <v-icon>
                                    {{
                                      item == 2
                                        ? "ri-checkbox-circle-fill"
                                        : "ri-close-circle-fill"
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-list v-else-if="
                            act.settings.is_show_discussion === true &&
                            act.settings.is_show_answer === true
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="answerExplan
                                ? answerExplan == true
                                  ? 'green'
                                  : answerExplan == false
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content :class="(item.text === answerTextExplan &&
                                  answerExplan === true &&
                                  correctAnswer === true) ||
                                  (item.text === correctTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false)
                                  ? 'success--text font-weight-bold'
                                  : item.text === answerTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false
                                    ? 'error--text font-weight-bold'
                                    : ''
                                  ">
                                  <div v-html="item.text" />
                                </v-list-item-content>
                                <v-list-item-icon>
                                  <v-icon>
                                    {{
                                      (item.text === answerTextExplan &&
                                        answerExplan === true &&
                                        correctAnswer === true) ||
                                        (item.text === correctTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false)
                                        ? "ri-checkbox-circle-fill success--text"
                                        : item.text === answerTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false
                                          ? "ri-close-circle-fill error--text"
                                          : ""
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-alert shaped dense prominent icon="ri-spam-2-fill" type="error"
                            v-show="currentQuestion.answer.length == 0">
                            Soal ini tidak dijawab.
                          </v-alert>

                          <div>
                            <div class="font-weight-bold">Pembahasan soal:</div>
                            <v-list class="options" disabled>
                              <v-list-item-group class="snow" color="primary">
                                <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded">
                                  <v-list-item-content>
                                    <div class="navy-soft--text" v-html="explanation.text" />
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-row>
              </template>
              <template v-else-if="act.type == 'quiz'">
                <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
                  <div class="mb-3 font-weight-bold text-capitalize ms-3 text-h6">
                    {{ act.title }}
                  </div>

                  <v-card v-if="act.is_complete == true || act.repeat_test > 0" class="col-md-12" elevation="0">
                    <div v-if="resultNilai" class="pa-4">
                      <v-row class="py-4" justify="center">
                        <v-col cols="8" class="justify-center d-flex">
                          <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                        </v-col>

                        <v-col cols="8" class="d-flex justify-space-between">
                          <div>Kesempatan mengulang</div>
                          <div v-if="act.settings.opportunity_repeat_test === -1">
                            Tanpa Batas
                          </div>
                          <div v-else>
                            <template v-if="
                              parseInt(
                                act.settings.opportunity_repeat_test -
                                act.repeat_test
                              ) < 0
                            ">
                              0x
                            </template>
                            <template v-else>
                              {{
                                act.settings.opportunity_repeat_test -
                                act.repeat_test
                              }}x
                            </template>
                          </div>
                        </v-col>
                        <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8"
                          class="d-flex justify-space-between">
                          <div>Nilai minimal</div>
                          <div>
                            {{ act.settings.set_minimum_value.minimum_value }}
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col v-if="act.settings.is_show_score === true" cols="8">
                          <div class="text-center font-weight-bold">Nilai</div>
                          <div class="text-center primary--text">
                            <span class="text-h3 font-weight-bold">
                              {{ act.score }}
                            </span>

                            <span class="text-h4 font-weight-bold" v-if="act.settings.set_minimum_value.is_set">/{{
                              act.settings.set_minimum_value.minimum_value }}</span>
                          </div>
                        </v-col>
                        <v-col md="6" sm="12">
                          <v-btn v-if="act.settings.is_show_discussion" rounded block color="primary" elevation="0"
                            large @click="
                              showExplanation = true;
                            resultNilai = false;
                            getExplan(act.settings.is_show_answer);
                            ">Lihat Jawaban</v-btn>
                          <!-- <v-btn
                        v-else-if="act.settings.opportunity_repeat_test > 0 && act.repeat_test >= act.settings.opportunity_repeat_test  && act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanation = true;
                          resultNilai = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      >  -->
                          <v-spacer class="py-2"></v-spacer>
                          <div v-if="
                            act.settings.opportunity_repeat_test === 0 ||
                            act.settings.opportunity_repeat_test -
                            act.repeat_test ===
                            0
                          "></div>
                          <div v-else>
                            <v-btn rounded block text color="primary" elevation="0" large
                              @click="redirect(act.type, act.settings)" :loading="activity_loading">Ulangi Kuis</v-btn>
                          </div>
                          <v-spacer class="py-2"></v-spacer>
                          <v-btn v-if="isHiddenButtonNextActivity" color="primary" depressed rounded block large
                            @click="getUnlockUpload()" :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn>
                          <!-- <v-btn v-if="
                            act.is_complete == false &&
                            act.score >=
                            act.settings.set_minimum_value.minimum_value
                          " class="mx-2" color="primary" rounded block @click="getUnlockUpload()"
                            :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn> -->
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="showQues" class="pa-4">
                      <div class="px-4 text-right" v-if="!activity_loading && currentQuestion.test.timer.active">
                        <v-btn depressed x-small dark class="rounded-lg" color="red">
                          Pengerjaan Soal : {{ totalSecondsQuestion | minutesAndSeconds }}
                        </v-btn>
                      </div>
                      <div class="pa-4 row align-center navy-soft--text">
                        <div id="box-navigasi" class="col-md-4 d-flex" style="gap: 8px">
                          <v-btn depressed small color="#EBF5FF" class="mr-1" @click="goNumber(act.current - 1)"
                            :disabled="act.current <= 1 || !act.settings.is_skip"
                            style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$prev</v-icon></v-btn>
                          <div class="mt-2 mr-1 text-center">
                            Soal no
                            <span class="primary--text">{{ act.current }}</span>
                            dari
                            {{ act.total_question }}
                          </div>
                          <v-btn v-if="act.settings.is_skip === false" small depressed color="#EBF5FF"
                            :disabled="isRequired" @click="goNumber(act.current + 1)"
                            style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                          <v-btn v-else depressed color="#EBF5FF" small :disabled="act.current ===
                            act.total_question
                            " @click="goNumber(act.current + 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                        </div>
                        <div class="col-md-4">
                          <div v-if="act.settings.is_show_timer === true"
                            class="mb-0 text-center fs-16 pa-2 color-black" color="primary">
                            <v-icon left>mdi-clock-time-three-outline</v-icon>
                            Sisa Waktu Berlangsung: {{ totalSeconds | minutesAndSeconds }}
                          </div>
                        </div>
                        <div class="justify-end col-md-4 d-flex align-end text-end">
                          <v-tooltip color="grey darken-3" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :disabled="!review && act.current !== act.total_question" depressed
                                :block="$vuetify.breakpoint.name === 'xs'" v-bind="attrs" v-on="on" class="rounded-lg"
                                color="primary" small @click="submitQuiz(act.settings)">
                                Submit
                              </v-btn>
                            </template>
                            <span>Submit</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="
                          user-select: none;
                          max-height: 64vh;
                          overflow: scroll;
                        ">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestion.test.question.text"></div>
                          <div v-if="currentQuestion.test.question.media != ''">
                            <audio-player class="d-inline-block" :src="currentQuestion.test.question.media[0]
                              .public_url
                              " :repeat_audio="act.settings.opportunity_repeat_audio" />
                          </div>
                          <v-radio-group :disabled="currentQuestion.test.timer.active && totalSecondsQuestion == 0 && !act.settings.is_skip"
                            hide-details="auto" v-model="currentQuestion.answer" class="options">
                            <v-radio v-for="(item, index) in optionQuestion" :key="index" class="mb-2" :value="index">
                              <template v-slot:label>
                                <div style="display: block">
                                  <div v-html="item.text"></div>
                                  <img width="250px" height="auto" class="mt-1 rounded-lg"
                                    :src="item.media[0].public_url" v-if="item.media.length > 0" />
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                    </div>
                    <div v-if="showExplanation" class="pa-4">
                      <div class="pa-4 navy-soft--text"
                        :class="$vuetify.breakpoint.name == 'xs' ? 'd-flex flex-column align-center' : 'd-flex align-center'">
                        <div class="d-flex align-center" :class="$vuetify.breakpoint.name == 'xs' ? 'mb-5' : ''"
                          style="gap: 8px">
                          <v-btn depressed small color="#EBF5FF" class="mr-1" @click="goNumber(act.current - 1)"
                            :disabled="act.current <= 1" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$prev</v-icon></v-btn>
                          <div class="mt-2 mr-1 text-center">
                            Soal no
                            <span class="primary--text">{{ act.current }}</span>
                            dari
                            {{ act.total_question }}
                          </div>
                          <v-btn depressed small color="#EBF5FF" :disabled="act.current >= act.total_question"
                            @click="goNumber(act.current + 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                        </div>
                        <v-spacer></v-spacer>
                        <v-tooltip v-if="!review" color="grey darken-3" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" depressed :block="$vuetify.breakpoint.name === 'xs'"
                              color="primary" class="rounded-lg" @click="
                                resultNilai = true;
                              showExplanation = false;
                              act.current = 1;
                              ">
                              Kembali
                            </v-btn>
                          </template>
                          <span>Kembali</span>
                        </v-tooltip>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="
                          user-select: none;
                          max-height: 64vh;
                          overflow: scroll;
                        ">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text"></div>

                          <v-list v-if="
                            act.settings.is_show_discussion === true &&
                            act.settings.is_show_answer === false
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow" color="primary">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="review
                                ? item == 2
                                  ? 'green'
                                  : item == 4
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content>
                                  {{ item.text }}
                                </v-list-item-content>
                                <v-list-item-icon v-if="review && [2, 4].includes(item)">
                                  <v-icon>
                                    {{
                                      item == 2
                                        ? "ri-checkbox-circle-fill"
                                        : "ri-close-circle-fill"
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-list v-else-if="
                            act.settings.is_show_discussion === true &&
                            act.settings.is_show_answer === true
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="answerExplan
                                ? answerExplan == true
                                  ? 'green'
                                  : answerExplan == false
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content :class="(item.text === answerTextExplan &&
                                  answerExplan === true &&
                                  correctAnswer === true) ||
                                  (item.text === correctTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false)
                                  ? 'success--text font-weight-bold'
                                  : item.text === answerTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false
                                    ? 'error--text font-weight-bold'
                                    : ''
                                  ">
                                  <div v-html="item.text" />
                                </v-list-item-content>
                                <v-list-item-icon>
                                  <v-icon>
                                    {{
                                      (item.text === answerTextExplan &&
                                        answerExplan === true &&
                                        correctAnswer === true) ||
                                        (item.text === correctTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false)
                                        ? "ri-checkbox-circle-fill success--text"
                                        : item.text === answerTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false
                                          ? "ri-close-circle-fill error--text"
                                          : ""
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-alert shaped dense prominent icon="ri-spam-2-fill" type="error"
                            v-show="currentQuestion.answer.length == 0">
                            Soal ini tidak dijawab.
                          </v-alert>

                          <div>
                            <div class="font-weight-bold">Pembahasan soal:</div>
                            <v-list class="options" disabled>
                              <v-list-item-group class="snow" color="primary">
                                <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded">
                                  <v-list-item-content>
                                    <div class="navy-soft--text" v-html="explanation.text"></div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                  <v-card v-else class="col-12" elevation="0">
                    <div v-if="resultNilaiFalse" class="pa-4">
                      <v-row class="py-4" justify="center">
                        <v-col cols="8" class="justify-center d-flex">
                          <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                        </v-col>

                        <v-col cols="8" class="d-flex justify-space-between">
                          <div>Kesempatan mengulang</div>
                          <div v-if="act.settings.opportunity_repeat_test === -1">
                            Tanpa Batas
                          </div>
                          <div v-else>
                            <template v-if="
                              parseInt(
                                act.settings.opportunity_repeat_test -
                                act.repeat_test
                              ) < 0
                            ">
                              0x
                            </template>
                            <template v-else>
                              {{
                                act.settings.opportunity_repeat_test -
                                act.repeat_test
                              }}x
                            </template>
                          </div>
                        </v-col>
                        <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8"
                          class="d-flex justify-space-between">
                          <div>Nilai minimal</div>
                          <div>
                            {{ act.settings.set_minimum_value.minimum_value }}
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col v-if="act.settings.is_show_score === true" cols="8">
                          <div class="text-center font-weight-bold">Nilai</div>
                          <div class="text-center primary--text">
                            <span class="text-h3 font-weight-bold">
                              {{ act.score }}
                            </span>
                            <span class="text-h4 font-weight-bold" v-if="act.settings.set_minimum_value.is_set">/{{
                              act.settings.set_minimum_value.minimum_value }}</span>
                          </div>
                        </v-col>
                        <v-col md="6" sm="12">
                          <v-btn v-if="act.settings.is_show_discussion" rounded block color="primary" elevation="0"
                            large @click="
                              showExplanationFalse = true;
                            resultNilaiFalse = false;
                            getExplan(act.settings.is_show_answer);
                            ">Lihat Jawaban</v-btn>
                          <!-- <v-btn
                        v-else-if="act.settings.opportunity_repeat_test > 0 && act.repeat_test >= act.settings.opportunity_repeat_test  && act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationFalse = true;
                          resultNilaiFalse = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      > -->
                          <v-spacer class="py-2"></v-spacer>
                          <div v-if="
                            act.settings.opportunity_repeat_test === 0 ||
                            act.settings.opportunity_repeat_test -
                            act.repeat_test ===
                            0
                          "></div>
                          <div v-else>
                            <v-btn rounded block text color="primary" elevation="0" large
                              @click="redirectFalse(act.type, act.settings)" :loading="activity_loading">
                              <span>Ulangi Kuis</span>
                            </v-btn>
                          </div>
                          <v-spacer class="py-2"></v-spacer>
                          <v-btn v-if="isHiddenButtonNextActivity" color="primary" depressed rounded block large
                            @click="getUnlockUpload()" :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn>
                          <!-- <v-btn v-if="
                            act.is_complete == false &&
                            act.score >=
                            act.settings.set_minimum_value.minimum_value
                          " class="mx-2" color="primary" rounded block @click="getUnlockUpload()"
                            :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn> -->
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="showQuesFalse" class="pa-4">
                      <div class="px-4 text-right" v-if="!activity_loading && currentQuestion.test.timer.active">
                        <v-btn depressed x-small dark class="rounded-lg" color="red">
                          Pengerjaan Soal : {{ totalSecondsQuestion | minutesAndSeconds }}
                        </v-btn>
                      </div>
                      <div class="pa-4 row align-center navy-soft--text">
                        <div id="box-navigasi" class="col-md-4 d-flex" style="gap: 8px">
                          <v-btn depressed small color="#EBF5FF" class="mr-1" @click="goNumber(act.current - 1)"
                            :disabled="act.current <= 1 || !act.settings.is_skip"
                            style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$prev</v-icon></v-btn>
                          <div class="mt-2 mr-1 text-center">
                            Soal no
                            <span class="primary--text">{{ act.current }}</span>
                            dari
                            {{ act.total_question }}
                          </div>
                          <v-btn v-if="act.settings.is_skip === false" small depressed color="#EBF5FF"
                            :disabled="isRequired" @click="goNumber(act.current + 1)"
                            style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                          <v-btn v-else depressed color="#EBF5FF" small :disabled="act.current ===
                            act.total_question
                            " @click="goNumber(act.current + 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                        </div>
                        <div class="col-md-4">
                          <div v-if="act.settings.is_show_timer === true"
                            class="mb-0 text-center fs-16 pa-2 color-black" color="primary">
                            <v-icon left>mdi-clock-time-three-outline</v-icon>
                            Sisa Waktu Berlangsung: {{ totalSeconds | minutesAndSeconds }}
                          </div>
                        </div>
                        <div class="justify-end col-md-4 d-flex align-end text-end">
                          <v-tooltip color="grey darken-3" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :disabled="!review && act.current !== act.total_question" depressed
                                :block="$vuetify.breakpoint.name === 'xs'" v-bind="attrs" v-on="on" class="rounded-lg"
                                color="primary" small @click="submitQuizFalse(act.settings)">
                                Submit
                              </v-btn>
                            </template>
                            <span>Submit</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="
                          user-select: none;
                          max-height: 64vh;
                          overflow: scroll;
                        ">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestion.test.question.text"></div>
                          <div v-if="currentQuestion.test.question.media != ''">
                            <audio-player class="d-inline-block" :src="currentQuestion.test.question.media[0]
                              .public_url
                              " :repeat_audio="act.settings.opportunity_repeat_audio" />
                          </div>
                          <v-radio-group :disabled="currentQuestion.test.timer.active && totalSecondsQuestion == 0 && !act.settings.is_skip"
                            hide-details="auto" v-model="currentQuestion.answer" class="options">
                            <v-radio v-for="(item, index) in optionQuestion" :key="index" class="mb-2" :value="index">
                              <template v-slot:label>
                                <div style="display: block">
                                  <div v-html="item.text"></div>
                                  <img width="250px" height="auto" class="mt-1 rounded-lg"
                                    :src="item.media[0].public_url" v-if="item.media.length > 0" />
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                          <div v-if="review">
                            <div class="font-weight-bold">Pembahasan soal:</div>
                            <v-list class="options" disabled>
                              <v-list-item-group class="snow" v-model="correct_answer" color="primary">
                                <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" v-for="item in 1"
                                  :key="item">
                                  <v-list-item-content>
                                    <div class="mb-4 green--text">
                                      Jawaban yang benar adalah
                                      {{ "Opsi " + item }}
                                    </div>
                                    <div class="navy-soft--text">
                                      In feugiat id nec habitant tortor.
                                      Faucibus montes.
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="showExplanationFalse" class="pa-4">
                      <div class="pa-4 d-flex align-center navy-soft--text">
                        <v-btn depressed small color="#EBF5FF" class="mr-1" @click="goNumber(act.current - 1)"
                          :disabled="act.current <= 1" style="min-width: auto; width: 30px !important">
                          <v-icon color="primary">$prev</v-icon></v-btn>
                        <div class="mt-2 mr-1 text-center">
                          Soal no
                          <span class="primary--text">{{ act.current }}</span>
                          dari
                          {{ act.total_question }}
                        </div>
                        <v-btn outlined color="primary" :disabled="act.current >= act.total_question"
                          @click="goNumber(act.current + 1)"
                          style="min-width: auto; width: 30px !important"><v-icon>$next</v-icon></v-btn>
                        <v-spacer></v-spacer>
                        <v-tooltip v-if="!review" color="grey darken-3" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" small color="primary" class="rounded-pill" @click="
                              resultNilaiFalse = true;
                            showExplanationFalse = false;
                            act.current = 1;
                            ">
                              Kembali
                            </v-btn>
                          </template>
                          <span>Kembali</span>
                        </v-tooltip>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="
                          user-select: none;
                          max-height: 64vh;
                          overflow: scroll;
                        ">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text"></div>

                          <v-list v-if="
                            act.settings.is_show_discussion === true &&
                            act.settings.is_show_answer === false
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow" color="primary">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="review
                                ? item == 2
                                  ? 'green'
                                  : item == 4
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content>
                                  {{ item.text }}
                                </v-list-item-content>
                                <v-list-item-icon v-if="review && [2, 4].includes(item)">
                                  <v-icon>
                                    {{
                                      item == 2
                                        ? "ri-checkbox-circle-fill"
                                        : "ri-close-circle-fill"
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-list v-else-if="
                            act.settings.is_show_discussion === true &&
                            act.settings.is_show_answer === true
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="answerExplan
                                ? answerExplan == true
                                  ? 'green'
                                  : answerExplan == false
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content :class="(item.text === answerTextExplan &&
                                  answerExplan === true &&
                                  correctAnswer === true) ||
                                  (item.text === correctTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false)
                                  ? 'success--text font-weight-bold'
                                  : item.text === answerTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false
                                    ? 'error--text font-weight-bold'
                                    : ''
                                  ">
                                  <div v-html="item.text" />
                                </v-list-item-content>
                                <v-list-item-icon>
                                  <v-icon>
                                    {{
                                      (item.text === answerTextExplan &&
                                        answerExplan === true &&
                                        correctAnswer === true) ||
                                        (item.text === correctTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false)
                                        ? "ri-checkbox-circle-fill success--text"
                                        : item.text === answerTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false
                                          ? "ri-close-circle-fill error--text"
                                          : ""
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-alert shaped dense prominent icon="ri-spam-2-fill" type="error"
                            v-show="currentQuestion.answer.length == 0">
                            Soal ini tidak dijawab.
                          </v-alert>

                          <div>
                            <div class="font-weight-bold">Pembahasan soal:</div>
                            <v-list class="options" disabled>
                              <v-list-item-group class="snow" color="primary">
                                <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded">
                                  <v-list-item-content>
                                    <div class="navy-soft--text" v-html="explanation.text"></div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-row>
              </template>
              <template v-else-if="act.type == 'post_test'">
                <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
                  <div class="mb-3 font-weight-bold text-capitalize ms-3 text-h6">
                    {{ act.title }}
                  </div>

                  <v-card v-if="act.is_complete == true" class="col-md-12" elevation="0">
                    <div v-if="resultNilaiPost" class="pa-4">
                      <v-row class="py-4" justify="center">
                        <v-col cols="8" class="justify-center d-flex">
                          <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                        </v-col>

                        <v-col cols="8" class="d-flex justify-space-between">
                          <div>Kesempatan mengulang</div>
                          <div v-if="act.settings.opportunity_repeat_test === -1">
                            Tanpa Batas
                          </div>
                          <div v-else>
                            <template v-if="
                              parseInt(
                                act.settings.opportunity_repeat_test -
                                act.repeat_test
                              ) < 0
                            ">
                              0x
                            </template>
                            <template v-else>
                              {{
                                act.settings.opportunity_repeat_test -
                                act.repeat_test
                              }}x
                            </template>
                          </div>
                        </v-col>
                        <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8"
                          class="d-flex justify-space-between">
                          <div>Nilai minimal</div>
                          <div>
                            {{ act.settings.set_minimum_value.minimum_value }}
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col v-if="act.settings.is_show_score === true" cols="8">
                          <div class="text-center font-weight-bold">Nilai</div>
                          <div class="text-center primary--text">
                            <span class="text-h3 font-weight-bold">
                              {{ act.score }}
                            </span>

                            <span class="text-h4 font-weight-bold" v-if="act.settings.set_minimum_value.is_set">/{{
                              act.settings.set_minimum_value.minimum_value }}</span>
                          </div>
                        </v-col>
                        <v-col md="6" sm="12">
                          <v-btn v-if="act.settings.is_show_discussion" rounded block color="primary" elevation="0"
                            large @click="
                              showExplanationPost = true;
                            resultNilaiPost = false;
                            getExplan(act.settings.is_show_answer);
                            ">Lihat Jawaban</v-btn>
                          <v-spacer class="py-2"></v-spacer>
                          <div v-if="act.settings.opportunity_repeat_test === 0"></div>
                          <div v-else>
                            <v-btn rounded block text color="primary" elevation="0" large
                              @click="redirectPost(act.type, act.settings)" :loading="activity_loading">Ulangi Post
                              Test</v-btn>
                          </div>
                          <v-spacer class="py-2"></v-spacer>
                          <v-btn v-if="isHiddenButtonNextActivity" color="primary" depressed rounded block large
                            @click="getUnlockUpload()" :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn>
                          <!-- <v-btn v-if="act.is_complete == false" color="primary" rounded block
                            @click="getUnlockUpload()" :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn> -->
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="showQuesPost" class="pa-4">
                      <div class="px-4 text-right" v-if="!activity_loading && currentQuestion.test.timer.active">
                        <v-btn depressed x-small dark class="rounded-lg" color="red">
                          Pengerjaan Soal : {{ totalSecondsQuestion | minutesAndSeconds }}
                        </v-btn>
                      </div>
                      <div class="pa-4 row align-center navy-soft--text">
                        <div id="box-navigasi" class="col-md-4 d-flex" style="gap: 8px">
                          <v-btn depressed small color="#EBF5FF" class="mr-1" @click="goNumber(act.current - 1)"
                            :disabled="act.current <= 1 || !act.settings.is_skip"
                            style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$prev</v-icon></v-btn>
                          <div class="mt-2 mr-1 text-center">
                            Soal no
                            <span class="primary--text">{{ act.current }}</span>
                            dari
                            {{ act.total_question }}
                          </div>
                          <v-btn v-if="act.settings.is_skip === false" small depressed color="#EBF5FF"
                            :disabled="isRequired" @click="goNumber(act.current + 1)"
                            style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                          <v-btn v-else depressed color="#EBF5FF" small :disabled="act.current ===
                            act.total_question
                            " @click="goNumber(act.current + 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                        </div>
                        <div class="col-md-4">
                          <div v-if="act.settings.is_show_timer === true"
                            class="mb-0 text-center fs-16 pa-2 color-black" color="primary">
                            <v-icon left>mdi-clock-time-three-outline</v-icon>
                            Sisa Waktu Berlangsung: {{ totalSeconds | minutesAndSeconds }}
                          </div>
                        </div>
                        <div class="justify-end col-md-4 d-flex align-end text-end">
                          <v-tooltip color="grey darken-3" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :disabled="!review && act.current !== act.total_question" depressed
                                :block="$vuetify.breakpoint.name === 'xs'" v-bind="attrs" v-on="on" class="rounded-lg"
                                color="primary" small @click="submitPost(act.settings)">
                                Submit
                              </v-btn>
                            </template>
                            <span>Submit</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="
                          user-select: none;
                          max-height: 64vh;
                          overflow: scroll;
                        ">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <!-- <div id="tagImg" class="text-justify" v-html="currentQuestion.question.text"> -->
                          <div id="tagImg" class="text-justify" v-html="currentQuestion.test.question.text"></div>
                          <div v-if="currentQuestion.test.question.media != ''">
                            <audio-player class="d-inline-block" :src="currentQuestion.test.question.media[0]
                              .public_url
                              " :repeat_audio="act.settings.opportunity_repeat_audio" />
                          </div>
                          <v-radio-group :disabled="currentQuestion.test.timer.active && totalSecondsQuestion == 0 && !act.settings.is_skip"
                            hide-details="auto" v-model="currentQuestion.answer" class="options">
                            <v-radio v-for="(item, index) in optionQuestion" :key="index" class="mb-2" :value="index">
                              <template v-slot:label>
                                <div v-html="item.text"></div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                    </div>
                    <div v-if="showExplanationPost" class="pa-4">
                      <div class="pa-4 d-flex align-center navy-soft--text"
                        :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column' : ''">
                        <div class="d-flex" :class="$vuetify.breakpoint.name == 'xs' ? 'mb-5' : ''" style="gap: 8px">
                          <v-btn depressed small color="#EBF5FF" :disabled="act.current <= 1"
                            @click="goNumber(act.current - 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$prev</v-icon></v-btn>
                          <div class="mt-2 mr-1 text-center">
                            Soal no
                            <span class="primary--text">{{ act.current }}</span>
                            dari
                            {{ act.total_question }}
                          </div>
                          <v-btn depressed color="#EBF5FF" small :disabled="act.current >= act.total_question"
                            @click="goNumber(act.current + 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                        </div>
                        <v-spacer></v-spacer>
                        <v-tooltip v-if="!review" color="grey darken-3" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn depressed :block="$vuetify.breakpoint.name === 'xs'" v-bind="attrs" v-on="on"
                              class="rounded-lg" color="primary" small @click="
                                resultNilaiPost = true;
                              showExplanationPost = false;
                              act.current = 1;
                              ">
                              Kembali
                            </v-btn>
                          </template>
                          <span>Kembali</span>
                        </v-tooltip>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="
                          user-select: none;
                          max-height: 64vh;
                          overflow: scroll;
                        ">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text"></div>

                          <v-list v-if="
                            act.settings.is_show_discussion === true &&
                            act.settings.is_show_answer === false
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow" color="primary">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="review
                                ? item == 2
                                  ? 'green'
                                  : item == 4
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content>
                                  {{ item.text }}
                                </v-list-item-content>
                                <v-list-item-icon v-if="review && [2, 4].includes(item)">
                                  <v-icon>
                                    {{
                                      item == 2
                                        ? "ri-checkbox-circle-fill"
                                        : "ri-close-circle-fill"
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-list v-else-if="
                            act.settings.is_show_discussion === true &&
                            act.settings.is_show_answer === true
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="answerExplan
                                ? answerExplan == true
                                  ? 'green'
                                  : answerExplan == false
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content :class="(item.text === answerTextExplan &&
                                  answerExplan === true &&
                                  correctAnswer === true) ||
                                  (item.text === correctTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false)
                                  ? 'success--text font-weight-bold'
                                  : item.text === answerTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false
                                    ? 'error--text font-weight-bold'
                                    : ''
                                  ">
                                  <div v-html="item.text" />
                                </v-list-item-content>
                                <v-list-item-icon>
                                  <v-icon>
                                    {{
                                      (item.text === answerTextExplan &&
                                        answerExplan === true &&
                                        correctAnswer === true) ||
                                        (item.text === correctTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false)
                                        ? "ri-checkbox-circle-fill success--text"
                                        : item.text === answerTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false
                                          ? "ri-close-circle-fill error--text"
                                          : ""
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-alert shaped dense prominent icon="ri-spam-2-fill" type="error"
                            v-show="currentQuestion.answer.length == 0">
                            Soal ini tidak dijawab.
                          </v-alert>

                          <div>
                            <div class="font-weight-bold">Pembahasan soal:</div>
                            <v-list class="options" disabled>
                              <v-list-item-group class="snow" color="primary">
                                <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded">
                                  <v-list-item-content>
                                    <div class="navy-soft--text" v-html="explanation.text"></div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                  <v-card v-else class="col-12" elevation="0">
                    <div v-if="resultNilaiPostFalse" class="pa-4">
                      <v-row class="py-4" justify="center">
                        <v-col cols="8" class="justify-center d-flex">
                          <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                        </v-col>

                        <v-col cols="8" class="d-flex justify-space-between">
                          <div>Kesempatan mengulang</div>
                          <div v-if="act.settings.opportunity_repeat_test === -1">
                            Tanpa Batas
                          </div>
                          <div v-else>
                            <template v-if="
                              parseInt(
                                act.settings.opportunity_repeat_test -
                                act.repeat_test
                              ) < 0
                            ">
                              0x
                            </template>
                            <template v-else>
                              {{
                                act.settings.opportunity_repeat_test -
                                act.repeat_test
                              }}x
                            </template>
                          </div>
                        </v-col>
                        <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8"
                          class="d-flex justify-space-between">
                          <div>Nilai minimal</div>
                          <div>
                            {{ act.settings.set_minimum_value.minimum_value }}
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col v-if="act.settings.is_show_score === true" cols="8">
                          <div class="text-center font-weight-bold">Nilai</div>
                          <div class="text-center primary--text">
                            <span class="text-h3 font-weight-bold">
                              {{ act.score }}
                            </span>

                            <span class="text-h4 font-weight-bold" v-if="act.settings.set_minimum_value.is_set">/{{
                              act.settings.set_minimum_value.minimum_value }}</span>
                          </div>
                        </v-col>
                        <v-col md="6" sm="12">
                          <v-btn v-if="act.settings.is_show_discussion" rounded block color="primary" elevation="0"
                            large @click="
                              showExplanationPostFalse = true;
                            resultNilaiPostFalse = false;
                            getExplan(act.settings.is_show_answer);
                            ">Lihat Jawaban</v-btn>
                          <!-- <v-btn
                        v-else-if="act.settings.opportunity_repeat_test > 0 && act.repeat_test >= act.settings.opportunity_repeat_test  && act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPostFalse = true;
                          resultNilaiPostFalse = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      >  -->
                          <v-spacer class="py-2"></v-spacer>
                          <div v-if="
                            act.settings.opportunity_repeat_test === 0 ||
                            act.settings.opportunity_repeat_test -
                            act.repeat_test ===
                            0
                          "></div>
                          <div v-else>
                            <v-btn rounded block text color="primary" elevation="0" large
                              @click="redirectPostFalse(act.type, act.settings)" :loading="activity_loading">Ulangi Post
                              Test</v-btn>
                          </div>
                          <v-spacer class="py-2"></v-spacer>
                          <v-btn v-if="isHiddenButtonNextActivity" color="primary" depressed rounded block large
                            @click="getUnlockUpload()" :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn>
                          <!-- <div>
                            <template v-if="act.settings.set_minimum_value.is_set">
                              <v-btn v-if="
                                act.score >=
                                act.settings.set_minimum_value
                                  .minimum_value && act.is_complete == false
                              " class="mx-2" color="primary" rounded block @click="getUnlockUpload()"
                                :loading="loadUpload">
                                Klik aktivitas selanjutnya
                              </v-btn>
                            </template>
                            <template v-else>
                              <v-btn v-if="act.is_complete == false" class="mx-2" color="primary" rounded block
                                @click="getUnlockUpload()" :loading="loadUpload">
                                Klik aktivitas selanjutnya
                              </v-btn>
                            </template>
                          </div> -->
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="showQuesPostFalse" class="pa-4">
                      <div class="px-4 text-right" v-if="!activity_loading && currentQuestion.test.timer.active">
                        <v-btn depressed x-small dark class="rounded-lg" color="red">
                          Pengerjaan Soal : {{ totalSecondsQuestion | minutesAndSeconds }}
                        </v-btn>
                      </div>
                      <div class="pa-4 row align-center navy-soft--text">
                        <div id="box-navigasi" class="col-md-4 d-flex" style="gap: 8px">
                          <v-btn depressed small color="#EBF5FF" class="mr-1" @click="goNumber(act.current - 1)"
                            :disabled="act.current <= 1 || !act.settings.is_skip"
                            style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$prev</v-icon></v-btn>
                          <div class="mt-2 mr-1 text-center">
                            Soal no
                            <span class="primary--text">{{ act.current }}</span>
                            dari
                            {{ act.total_question }}
                          </div>
                          <v-btn v-if="act.settings.is_skip === false" small depressed color="#EBF5FF"
                            :disabled="isRequired" @click="goNumber(act.current + 1)"
                            style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                          <v-btn v-else depressed color="#EBF5FF" small :disabled="act.current ===
                            act.total_question
                            " @click="goNumber(act.current + 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                        </div>
                        <div class="col-md-4">
                          <div v-if="act.settings.is_show_timer === true"
                            class="mb-0 text-center fs-16 pa-2 color-black" color="primary">
                            <v-icon left>mdi-clock-time-three-outline</v-icon>
                            Sisa Waktu Berlangsung: {{ totalSeconds | minutesAndSeconds }}
                          </div>
                        </div>
                        <div class="justify-end col-md-4 align-end text-end">
                          <v-tooltip color="grey darken-3" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :disabled="!review && act.current !== act.total_question" depressed v-bind="attrs"
                                v-on="on" class="rounded-lg" color="primary" small
                                @click="submitPostFalse(act.settings)">
                                Submit
                              </v-btn>
                            </template>
                            <span>Submit</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="
                          user-select: none;
                          max-height: 64vh;
                          overflow: scroll;
                        ">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestion.test.question.text"></div>
                          <div v-if="currentQuestion.test.question.media != ''">
                            <audio-player class="d-inline-block" :src="currentQuestion.test.question.media[0]
                              .public_url
                              " :repeat_audio="act.settings.opportunity_repeat_audio" />
                          </div>
                          <v-radio-group :disabled="currentQuestion.test.timer.active && totalSecondsQuestion == 0 && !act.settings.is_skip"
                            hide-details="auto" v-model="currentQuestion.answer" class="options">
                            <v-radio v-for="(item, index) in optionQuestion" :key="index" class="mb-2" :value="index">
                              <template v-slot:label>
                                <div style="display: block">
                                  <div v-html="item.text"></div>
                                  <img width="250px" height="auto" class="mt-1 rounded-lg"
                                    :src="item.media[0].public_url" v-if="item.media.length > 0" />
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                    </div>
                    <div v-if="showExplanationPostFalse" class="pa-4">
                      <div class="pa-4 d-flex align-center navy-soft--text"
                        :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column' : ''">
                        <div class="d-flex" :class="$vuetify.breakpoint.name == 'xs' ? 'mb-5' : ''" style="gap: 8px">
                          <v-btn depressed small color="#EBF5FF" :disabled="act.current <= 1"
                            @click="goNumber(act.current - 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$prev</v-icon></v-btn>
                          <div class="mt-2 mr-1 text-center">
                            Soal no
                            <span class="primary--text">{{ act.current }}</span>
                            dari
                            {{ act.total_question }}
                          </div>
                          <v-btn depressed color="#EBF5FF" small :disabled="act.current >= act.total_question"
                            @click="goNumber(act.current + 1)" style="min-width: auto; width: 30px !important">
                            <v-icon color="primary">$next</v-icon>
                          </v-btn>
                        </div>
                        <v-spacer></v-spacer>
                        <v-tooltip v-if="!review" color="grey darken-3" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn depressed :block="$vuetify.breakpoint.name === 'xs'" v-bind="attrs" v-on="on"
                              class="rounded-lg" color="primary" small @click="
                                resultNilaiPostFalse = true;
                              showExplanationPostFalse = false;
                              act.current = 1;
                              ">
                              Kembali
                            </v-btn>
                          </template>
                          <span>Kembali</span>
                        </v-tooltip>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="
                          user-select: none;
                          max-height: 64vh;
                          overflow: scroll;
                        ">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text"></div>

                          <v-list v-if="
                            act.settings.is_show_discussion === true &&
                            act.settings.is_show_answer === false
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow" color="primary">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="review
                                ? item == 2
                                  ? 'green'
                                  : item == 4
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content>
                                  {{ item.text }}
                                </v-list-item-content>
                                <v-list-item-icon v-if="review && [2, 4].includes(item)">
                                  <v-icon>
                                    {{
                                      item == 2
                                        ? "ri-checkbox-circle-fill"
                                        : "ri-close-circle-fill"
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-list v-else-if="
                            act.settings.is_show_discussion === true &&
                            act.settings.is_show_answer === true
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="answerExplan
                                ? answerExplan == true
                                  ? 'green'
                                  : answerExplan == false
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content :class="(item.text === answerTextExplan &&
                                  answerExplan === true &&
                                  correctAnswer === true) ||
                                  (item.text === correctTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false)
                                  ? 'success--text font-weight-bold'
                                  : item.text === answerTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false
                                    ? 'error--text font-weight-bold'
                                    : ''
                                  ">
                                  <div v-html="item.text" />
                                </v-list-item-content>
                                <v-list-item-icon>
                                  <v-icon>
                                    {{
                                      (item.text === answerTextExplan &&
                                        answerExplan === true &&
                                        correctAnswer === true) ||
                                        (item.text === correctTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false)
                                        ? "ri-checkbox-circle-fill success--text"
                                        : item.text === answerTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false
                                          ? "ri-close-circle-fill error--text"
                                          : ""
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-alert shaped dense prominent icon="ri-spam-2-fill" type="error"
                            v-show="currentQuestion.answer.length == 0">
                            Soal ini tidak dijawab.
                          </v-alert>

                          <div>
                            <div class="font-weight-bold">Pembahasan soal:</div>
                            <v-list class="options" disabled>
                              <v-list-item-group class="snow" color="primary">
                                <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded">
                                  <v-list-item-content>
                                    <div class="navy-soft--text" v-html="explanation.text"></div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-row>
              </template>
              <template v-else-if="act.type == 'exam'">
                <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
                  <div class="mb-3 font-weight-bold text-capitalize ms-3 text-h6">
                    {{ act.title }}
                  </div>

                  <!-- {{ currentQuestionExam.timer }}

                  {{ totalSecondsQuestion | minutesAndSeconds }} -->

                  <v-card v-if="act.is_complete == true || act.repeat_test > 0" class="col-md-12" elevation="0">
                    <div v-if="resultNilaiExam" class="pa-4">
                      <v-row class="py-4" justify="center">
                        <v-col cols="8" class="justify-center d-flex">
                          <v-icon class="d-block" color="grey lighten-2" size="60">ri-file-edit-fill</v-icon>
                        </v-col>

                        <v-col cols="10">
                          <v-data-table class="custom-table" :headers="headers_nilai" :items="act.section"
                            hide-default-footer hide-default-sort-column>
                            <template v-slot:[`item.result_score`]="{ item }">
                              {{
                                item.score >= item.min_score
                                  ? "Lulus"
                                  : "Tidak Lulus"
                              }}
                            </template>
                          </v-data-table>
                        </v-col>

                        <v-col v-if="act.settings.is_show_score === true" cols="8" class="text-center">
                          <div class="text-center font-weight-bold">Nilai</div>
                          <div class="mt-3 text-center primary--text">
                            <span class="text-h2 font-weight-bold">
                              {{ act.score }}
                            </span>
                          </div>
                          <div v-if="result_exam == false" class="mt-2 font-weight-bold">
                            Keterangan : Tidak Lulus
                          </div>
                          <div v-else class="mt-2 font-weight-bold">
                            Keterangan : Lulus
                          </div>

                          <div class="mt-2" v-if="act.settings.opportunity_repeat_test === -1">
                            Kesempatan mengulang : Tanpa Batas
                          </div>
                          <!-- <div class="mt-2" v-else-if="(act.settings.opportunity_repeat_test - act.repeat_test) === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) < -1">Kesempatan mengulang : -</div> -->
                          <div class="mt-2" v-else>
                            Kesempatan mengulang :
                            <span v-if="
                              act.settings.opportunity_repeat_test +
                              1 -
                              act.repeat_test >
                              0
                            ">{{
                              act.settings.opportunity_repeat_test +
                              1 -
                              act.repeat_test
                            }}</span>
                            <span v-else>0</span>
                            x
                          </div>
                        </v-col>
                        <v-col md="6" sm="12">
                          <v-btn v-if="act.settings.is_show_discussion" rounded block color="primary" elevation="0"
                            large @click="
                              showExplanationExam = true;
                            resultNilaiExam = false;
                            getExplanExam();
                            ">Lihat Jawaban</v-btn>
                          <v-spacer class="py-2"></v-spacer>
                          <div v-if="
                            act.settings.opportunity_repeat_test == -1 ||
                            act.repeat_test <
                            act.settings.opportunity_repeat_test + 1
                          ">
                            <v-btn rounded block text color="primary" elevation="0" large
                              @click="redirectExam(act.type, act.settings)" :loading="activity_loading">Ulangi
                              Test</v-btn>
                          </div>
                          <v-spacer class="py-2"></v-spacer>
                          <v-btn v-if="isHiddenButtonNextActivity" color="primary" depressed rounded block large
                            @click="getUnlockUpload()" :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn>
                          <!-- <v-btn v-if="act.is_complete == false" class="mx-2" color="primary" rounded block
                            @click="getUnlockUpload()" :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn> -->
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="showQuesExam" class="pa-4">
                      <div class="px-4 text-right" v-if="!activity_loading && currentQuestionExam.timer.active">
                        <v-btn depressed x-small dark class="rounded-lg" color="red">
                          Pengerjaan Soal : {{ totalSecondsQuestion | minutesAndSeconds }}
                        </v-btn>
                      </div>
                      <v-sheet class="mx-auto" max-width="98%">
                        <v-slide-group show-arrows>
                          <v-slide-item v-for="index in act.total_question" :key="index">
                            <v-btn :class="question_status(index)" x-small outlined :disabled="isRequiredExam"
                              class="py-3 mx-2 my-2 text-justify fs-12" style="width: 35px" elevation="0"
                              @click="goNumber(index)" v-bind:id="index">
                              {{ index }}
                            </v-btn>
                          </v-slide-item>
                        </v-slide-group>
                      </v-sheet>
                      <div class="pa-4 row align-center navy-soft--text">
                        <div class="col-md-8">
                          <div v-if="act.settings.is_show_timer === true" class="mb-0 fs-16 pa-2 color-black"
                            color="primary">
                            <v-icon left>mdi-clock-time-three-outline</v-icon>
                            Sisa Waktu Berlangsung: {{ totalSeconds | minutesAndSeconds }}
                          </div>
                        </div>
                        <div class="justify-end col-md-4 d-flex">
                          <v-tooltip color="grey darken-3" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :disabled="!review && act.current !== act.total_question" depressed
                                :block="$vuetify.breakpoint.name === 'xs'" v-bind="attrs" v-on="on" class="rounded-lg"
                                color="primary" small @click="submitExam(act.settings)">
                                Submit
                              </v-btn>
                            </template>
                            <span>Submit</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="
                          user-select: none;
                          max-height: 64vh;
                          overflow: scroll;
                        ">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestionExam.question.text"></div>
                          <div v-if="currentQuestionExam.question.media != ''">
                            <div v-if="
                              currentQuestionExam.question.media[0].type ==
                              'image'
                            ">
                              <v-img :src="currentQuestionExam.question.media[0]
                                .public_url
                                "></v-img>
                            </div>
                            <div v-else>
                              <audio-player class="d-inline-block" :src="currentQuestionExam.question.media[0]
                                .public_url
                                " :repeat_audio="act.settings.opportunity_repeat_audio" />
                            </div>
                          </div>
                          <v-radio-group :disabled="currentQuestionExam.timer.active && totalSecondsQuestion == 0 && !act.settings.is_skip"
                            hide-details="auto" v-model="currentQuestionExam.answer" class="options">
                            <v-radio v-for="(item, index) in optionQuestionExam" :key="index" class="mb-2"
                              :value="index">
                              <template v-slot:label>
                                <div v-if="item.media != ''">
                                  <div>
                                    <!-- <div v-if="item.media[index].type == 'image'"> -->
                                    <v-img :src="item.media[0].public_url"></v-img>
                                  </div>
                                  <!-- <div v-else>
                                <audio-player
                                  class="d-inline-block"
                                  :src="item.media[index].public_url"                       
                                />
                              </div> -->
                                </div>
                                <div v-html="item.text"></div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                    </div>
                    <div v-if="showExplanationExam" class="pa-4">
                      <v-sheet class="mx-auto" max-width="98%">
                        <v-slide-group show-arrows>
                          <v-slide-item v-for="index in act.total_question" :key="index">
                            <v-btn :class="question_status(index)" x-small outlined
                              class="py-3 mx-2 my-2 text-justify fs-12" style="width: 35px" elevation="0"
                              @click="goNumber(index)" v-bind:id="index">
                              {{ index }}
                            </v-btn>
                          </v-slide-item>
                        </v-slide-group>
                      </v-sheet>
                      <div class="pa-4 d-flex align-center navy-soft--text">
                        <v-spacer></v-spacer>
                        <v-tooltip v-if="!review" color="grey darken-3" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" depressed small color="primary" class="rounded-lg" @click="
                              resultNilaiExam = true;
                            showExplanationExam = false;
                            act.current = 1;
                            ">
                              Kembali
                            </v-btn>
                          </template>
                          <span>Kembali</span>
                        </v-tooltip>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="
                          user-select: none;
                          max-height: 64vh;
                          overflow: scroll;
                        ">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text"></div>

                          <v-list v-if="
                            act.settings.is_show_discussion == true &&
                            act.settings.is_show_answer == false
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow" color="primary">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="review
                                ? item == 2
                                  ? 'green'
                                  : item == 4
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content>
                                  {{ item.text }}
                                </v-list-item-content>
                                <v-list-item-icon v-if="review && [2, 4].includes(item)">
                                  <v-icon>
                                    {{
                                      item == 2
                                        ? "ri-checkbox-circle-fill"
                                        : "ri-close-circle-fill"
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-list v-else-if="
                            act.settings.is_show_discussion == true &&
                            act.settings.is_show_answer == true
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="answerExplan
                                ? answerExplan == true
                                  ? 'green'
                                  : answerExplan == false
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content :class="(item.text === answerTextExplan &&
                                  answerExplan === true &&
                                  correctAnswer === true) ||
                                  (item.text === correctTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false)
                                  ? 'success--text font-weight-bold'
                                  : item.text === answerTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false
                                    ? 'error--text font-weight-bold'
                                    : ''
                                  ">
                                  <div v-html="item.text" />
                                </v-list-item-content>
                                <v-list-item-icon>
                                  <v-icon>
                                    {{
                                      (item.text === answerTextExplan &&
                                        answerExplan === true &&
                                        correctAnswer === true) ||
                                        (item.text === correctTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false)
                                        ? "ri-checkbox-circle-fill success--text"
                                        : item.text === answerTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false
                                          ? "ri-close-circle-fill error--text"
                                          : ""
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-alert shaped dense prominent icon="ri-spam-2-fill" type="error"
                            v-show="!answerTextExplan">
                            Soal ini tidak dijawab.
                          </v-alert>

                          <div>
                            <div class="font-weight-bold">Pembahasan soal:</div>
                            <v-list class="options" disabled>
                              <v-list-item-group class="snow" color="primary">
                                <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded">
                                  <v-list-item-content>
                                    <!-- nanti di comment lagi
                                <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->

                                    <div class="navy-soft--text" v-html="explanation.text"></div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                  <v-card v-else class="col-12" elevation="0">
                    <div v-if="resultNilaiExamFalse" class="pa-4">
                      <v-row class="py-4" justify="center">
                        <v-col cols="8" class="justify-center d-flex">
                          <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                        </v-col>

                        <v-col cols="10">
                          <v-data-table class="custom-table" :headers="headers_nilai" :items="act.section"
                            hide-default-footer hide-default-sort-column>
                            <template v-slot:[`item.result_score`]="{ item }">
                              {{
                                item.score >= item.min_score
                                  ? "Lulus"
                                  : "Tidak Lulus"
                              }}
                            </template>
                          </v-data-table>
                        </v-col>

                        <v-col v-if="act.settings.is_show_score === true" cols="8" class="text-center">
                          <div class="text-center font-weight-bold">Nilai</div>
                          <div class="text-center primary--text">
                            <span class="mt-3 text-h2 font-weight-bold">
                              {{ act.score }}
                            </span>

                          </div>
                          <div v-if="result_exam == false" class="mt-2 font-weight-bold">
                            Keterangan : Tidak Lulus
                          </div>
                          <div v-else class="mt-2 font-weight-bold">
                            Keterangan : Lulus
                          </div>
                          <div class="mt-2" v-if="act.settings.opportunity_repeat_test === -1">
                            Kesempatan mengulang : Tanpa Batas
                          </div>
                          <!-- <div class="mt-2" v-else-if="(act.settings.opportunity_repeat_test - act.repeat_test) === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) < -1">Kesempatan mengulang : -</div> -->
                          <div class="mt-2" v-else>
                            Kesempatan mengulang :
                            <span v-if="
                              act.settings.opportunity_repeat_test +
                              1 -
                              act.repeat_test >
                              0
                            ">{{
                              act.settings.opportunity_repeat_test +
                              1 -
                              act.repeat_test
                            }}</span>
                            <span v-else>0</span>
                            x
                          </div>
                        </v-col>
                        <v-col md="6" sm="12">
                          <v-btn v-if="act.settings.is_show_discussion" rounded block color="primary" elevation="0"
                            large @click="
                              showExplanationExamFalse = true;
                            resultNilaiExamFalse = false;
                            getExplanExam();
                            ">Lihat Jawaban</v-btn>
                          <v-spacer class="py-2"></v-spacer>
                          <div v-if="
                            act.settings.opportunity_repeat_test == -1 ||
                            act.repeat_test <
                            act.settings.opportunity_repeat_test + 1
                          ">
                            <v-btn rounded block text color="primary" elevation="0" large
                              @click="redirectExamFalse(act.type, act.settings)" :loading="activity_loading">Ulangi
                              Test</v-btn>
                          </div>
                          <v-spacer class="py-2"></v-spacer>
                          <v-btn v-if="isHiddenButtonNextActivity" color="primary" depressed rounded block large
                            @click="getUnlockUpload()" :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn>
                          <!-- <v-btn v-if="act.is_complete == false" class="mx-2" color="primary" rounded block
                            @click="getUnlockUpload()" :loading="loadUpload">
                            Klik aktivitas selanjutnya
                          </v-btn> -->
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="showQuesExamFalse" class="pa-4">
                      <div class="px-4 text-right" v-if="!activity_loading && currentQuestionExam.timer.active">
                        <v-btn depressed x-small dark class="rounded-lg" color="red">
                          Pengerjaan Soal : {{ totalSecondsQuestion | minutesAndSeconds }}
                        </v-btn>
                      </div>
                      <v-sheet class="mx-auto" max-width="98%">
                        <v-slide-group show-arrows>
                          <v-slide-item v-for="index in act.total_question" :key="index">
                            <v-btn :class="question_status(index)" :disabled="isRequiredExam" x-small outlined
                              class="py-3 mx-2 my-2 text-justify fs-12" style="width: 35px" elevation="0"
                              @click="goNumber(index)" v-bind:id="index">
                              {{ index }}
                            </v-btn>
                          </v-slide-item>
                        </v-slide-group>
                      </v-sheet>
                      <div class="pa-4 row align-center navy-soft--text">
                        <div class="col-md-4">
                          <div v-if="act.settings.is_show_timer === true" class="mb-0 fs-16 pa-2 color-black"
                            color="primary">
                            <v-icon left>mdi-clock-time-three-outline</v-icon>
                            Sisa Waktu Berlangsung: {{ totalSeconds | minutesAndSeconds }}
                          </div>
                        </div>
                        <div class="justify-end col-md-4 d-flex">
                          <v-tooltip color="grey darken-3" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :disabled="!review && act.current !== act.total_question" depressed v-bind="attrs"
                                v-on="on" class="rounded-lg" color="primary" small
                                @click="submitExamFalse(act.settings)">
                                Submit
                              </v-btn>
                            </template>
                            <span>Submit</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="
                          user-select: none;
                          max-height: 64vh;
                          overflow: scroll;
                        ">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestionExam.question.text"></div>
                          <div v-if="currentQuestionExam.question.media != ''">
                            <div v-if="
                              currentQuestionExam.question.media[0].type ==
                              'image'
                            ">
                              <v-img :src="currentQuestionExam.question.media[0]
                                .public_url
                                "></v-img>
                            </div>
                            <div v-else>
                              <audio-player class="d-inline-block" :src="currentQuestionExam.question.media[0]
                                .public_url
                                " :repeat_audio="act.settings.opportunity_repeat_audio" />
                            </div>
                          </div>

                          <v-radio-group :disabled="currentQuestionExam.timer.active && totalSecondsQuestion == 0 && !act.settings.is_skip"
                            hide-details="auto" v-model="currentQuestionExam.answer" class="options">
                            <v-radio v-for="(item, index) in optionQuestionExam" :key="index" class="mb-2"
                              :value="index">
                              <template v-slot:label>
                                <div v-if="item.media != ''">
                                  <div>
                                    <v-img :src="item.media[0].public_url"></v-img>
                                  </div>
                                </div>
                                <div v-html="item.text"></div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                    </div>
                    <div v-if="showExplanationExamFalse" class="pa-4">
                      <v-sheet class="mx-auto" max-width="98%">
                        <v-slide-group show-arrows>
                          <v-slide-item v-for="index in act.total_question" :key="index">
                            <v-btn :class="question_status(index)" x-small outlined
                              class="py-3 mx-2 my-2 text-justify fs-12" style="width: 35px" elevation="0"
                              @click="goNumber(index)" v-bind:id="index">
                              {{ index }}
                            </v-btn>
                          </v-slide-item>
                        </v-slide-group>
                      </v-sheet>
                      <div class="pa-4 d-flex align-center navy-soft--text">
                        <v-spacer></v-spacer>
                        <v-tooltip v-if="!review" color="grey darken-3" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" depressed small color="primary" class="rounded-lg" @click="
                              resultNilaiExamFalse = true;
                            showExplanationExamFalse = false;
                            act.current = 1;
                            ">
                              Kembali
                            </v-btn>
                          </template>
                          <span>Kembali</span>
                        </v-tooltip>
                      </div>
                      <v-divider></v-divider>
                      <div v-if="activity_loading" class="text-center pa-4">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div v-else class="pa-4 d-flex" style="
                          user-select: none;
                          max-height: 64vh;
                          overflow: scroll;
                        ">
                        <div class="navy-soft--text" style="width: 2rem">
                          {{ act.current }}.
                        </div>
                        <div class="col pa-0">
                          <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text"></div>

                          <v-list v-if="
                            act.settings.is_show_discussion == true &&
                            act.settings.is_show_answer == false
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow" color="primary">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="review
                                ? item == 2
                                  ? 'green'
                                  : item == 4
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content>
                                  {{ item.text }}
                                </v-list-item-content>
                                <v-list-item-icon v-if="review && [2, 4].includes(item)">
                                  <v-icon>
                                    {{
                                      item == 2
                                        ? "ri-checkbox-circle-fill"
                                        : "ri-close-circle-fill"
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-list v-else-if="
                            act.settings.is_show_discussion == true &&
                            act.settings.is_show_answer == true
                          " class="options" disabled>
                            <v-list-item-group :multiple="review" class="snow">
                              <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded" :color="answerExplan
                                ? answerExplan == true
                                  ? 'green'
                                  : answerExplan == false
                                    ? 'error'
                                    : ''
                                : ''
                                " v-for="(item, index) in optionQuestionExplan" :key="index">
                                <v-list-item-content :class="(item.text === answerTextExplan &&
                                  answerExplan === true &&
                                  correctAnswer === true) ||
                                  (item.text === correctTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false)
                                  ? 'success--text font-weight-bold'
                                  : item.text === answerTextExplan &&
                                    answerExplan === true &&
                                    correctAnswer === false
                                    ? 'error--text font-weight-bold'
                                    : ''
                                  ">
                                  <div v-html="item.text" />
                                </v-list-item-content>
                                <v-list-item-icon>
                                  <v-icon>
                                    {{
                                      (item.text === answerTextExplan &&
                                        answerExplan === true &&
                                        correctAnswer === true) ||
                                        (item.text === correctTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false)
                                        ? "ri-checkbox-circle-fill success--text"
                                        : item.text === answerTextExplan &&
                                          answerExplan === true &&
                                          correctAnswer === false
                                          ? "ri-close-circle-fill error--text"
                                          : ""
                                    }}
                                  </v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-alert shaped dense prominent icon="ri-spam-2-fill" type="error"
                            v-show="!answerTextExplan">
                            Soal ini tidak dijawab.
                          </v-alert>

                          <div>
                            <div class="font-weight-bold">Pembahasan soal:</div>
                            <v-list class="options" disabled>
                              <v-list-item-group class="snow" color="primary">
                                <v-list-item class="px-4 py-3 mb-4 overflow-hidden rounded">
                                  <v-list-item-content>
                                    <div class="navy-soft--text" v-html="explanation.text"></div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-row>
              </template>
              <template v-else-if="act.type == 'praktek_mandiri'">
                <ActPraktekMandiri :act="act" :detail_topic="detailTopic" :id_topic="id_topic" :id_act="id_act"
                  @getDetailClass="getDetailClass">
                </ActPraktekMandiri>
              </template>
              <template v-else-if="act.type == 'evaluasi_praktek_mandiri'">
                <ActEvaluasiPraktekMandiri :act="act" :detail_class="detailClass" :detail_topic="detailTopic"
                  :id_topic="id_topic" :id_act="id_act" @getDetailClass="getDetailClass" @getActFalse="getActFalse">
                </ActEvaluasiPraktekMandiri>
              </template>
              <template v-else-if="act.type == 'berdiskusi_daring'">
                <act-berdiskusi :act="act" :detail_topic="detailTopic" :loading="loadUpload"
                  @click:finish="changeStatus()" @click:link="handleClickMeetingLink" />
              </template>
              <template v-else-if="act.type == 'berdiskusi_luring'">
                <act-berdiskusi :act="act" :detail_topic="detailTopic" :loading="loadUpload"
                  @click:finish="changeStatus()" @click:link="handleClickMeetingLink" />
              </template>
              <template v-else-if="act.type == 'evaluasi'">
                <ActEvaluasi :act="act"> </ActEvaluasi>
              </template>
              <template v-else-if="act.type == 'membaca-e-book'">
                <ActMembacaEbook :act="act"> </ActMembacaEbook>
              </template>
              <template v-else>
                <!-- Check jika topic menggunakan liveness dan liveness success nya null -->
                <div v-if="topic_use_liveness && (!topic_liveness_is_success || topic_liveness_is_success == null)">
                  <v-row justify="center" align="center">
                    <v-col cols="12">
                      <v-card>
                        <v-card-text class="mb-3 text-center py-9">
                          <img :src="require('@/assets/images/face-recognition.png')" alt="" srcset="" class="mx-auto"
                            width="180">
                          <div class="my-4 body-2 text-uppercase font-weight-black">Mohon maaf topik {{ detailTopic.name
                            }} anda belum terverifikasi</div>
                          <div class="my-4">Silahkan lakukan verifikasi wajah ulang untuk melanjutkan pengerjaan topik
                            {{ detailTopic.name }} </div>
                          <div v-show="loadingState">
                            <v-progress-circular indeterminate color="primary">
                            </v-progress-circular>
                          </div>
                          <div v-show="!loadingState">
                            <v-btn v-if="stateCallbackLiveness == ''" class="primary" :loading="loadingLiveness"
                              @click="createLivesnessPrakerja(id_topic, null)">
                              Verifikasi Wajah Sekarang
                            </v-btn>
                            <v-btn v-else class="primary" :loading="loadingLiveness"
                              :href="`/prakerja/callback?state=${stateCallbackLiveness}`">
                              Verifikasi Sekarang
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-alert v-if="responseCreateLiveness" type="error" outlined>
                        {{ responseCreateLiveness }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </div>

                <!-- Apabila topik liveness dan liveness success nya tidak null, kemudian check ketika aktivitas menggunakan liveness dan liveness success nya nulll -->

                <div
                  v-else-if="topic_use_liveness && topic_liveness_is_success && activity_use_liveness && (!activity_liveness_is_success || activity_liveness_is_success == null)">
                  <v-row justify="center" align="center">
                    <v-col cols="12">
                      <v-card>
                        <v-card-text class="mb-3 text-center py-9">
                          <img :src="require('@/assets/images/face-recognition.png')" alt="" srcset="" class="mx-auto"
                            width="180">
                          <div class="my-4 body-2 text-uppercase font-weight-black">Mohon maaf aktifitas {{ act.title }}
                            anda belum terverifikasi</div>
                          <div class="my-4">Silahkan lakukan verifikasi wajah ulang untuk melanjutkan pengerjaan
                            aktifitas {{ act.title }} </div>
                          <div v-show="loadingState">
                            <v-progress-circular indeterminate color="primary">
                            </v-progress-circular>
                          </div>
                          <div v-show="!loadingState">
                            <v-btn v-if="stateCallbackLiveness == ''" class="primary" :loading="loadingLiveness"
                              @click="createLivesnessPrakerja(id_topic, id_act)">
                              Verifikasi Wajah Sekarang
                            </v-btn>
                            <v-btn v-else class="primary" :loading="loadingLiveness"
                              :href="`/prakerja/callback?state=${stateCallbackLiveness}`">
                              Verifikasi Sekarang
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-alert v-if="responseCreateLiveness" type="error" outlined>
                        {{ responseCreateLiveness }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </div>

                <!-- Apabila topik tidak liveness dan liveness success nya null, kemudian check ketika aktivitas menggunakan liveness dan liveness success nya nulll -->
                <div
                  v-else-if="!topic_use_liveness && !topic_liveness_is_success && activity_use_liveness && (!activity_liveness_is_success || activity_liveness_is_success == null)">
                  <v-row justify="center" align="center">
                    <v-col cols="12">
                      <v-card>
                        <v-card-text class="mb-3 text-center py-9">
                          <img :src="require('@/assets/images/face-recognition.png')" alt="" srcset="" class="mx-auto"
                            width="180">
                          <div class="my-4 body-2 text-uppercase font-weight-black">Mohon maaf Aktifitas {{ act.title }}
                            anda belum terverifikasi</div>
                          <div class="my-4">Silahkan lakukan verifikasi wajah ulang untuk melanjutkan pengerjaan
                            aktifitas {{ act.title }} </div>
                          <div v-show="loadingState">
                            <v-progress-circular indeterminate color="primary">
                            </v-progress-circular>
                          </div>
                          <div v-show="!loadingState">
                            <v-btn v-if="stateCallbackLiveness == ''" class="primary" :loading="loadingLiveness"
                              @click="createLivesnessPrakerja(id_topic, id_act)">
                              Verifikasi Wajah Sekarang
                            </v-btn>
                            <v-btn v-else class="primary" :loading="loadingLiveness"
                              :href="`/prakerja/callback?state=${stateCallbackLiveness}`">
                              Verifikasi Sekarang
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-alert v-if="responseCreateLiveness" type="error" outlined>
                        {{ responseCreateLiveness }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </div>

                <v-row v-else :no-gutters="$vuetify.breakpoint.smAndUp" class="bg-white">
                  <v-tabs id="" v-model="tabs" color="primary" grow background-color="primary" class="default-tabs">
                    <v-tab class="text-capitalize">Deskripsi</v-tab>
                    <v-tab class="text-capitalize">Fasilitator</v-tab>
                    <v-tab class="text-capitalize">Ulasan</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabs" class="setting col-md-12">
                    <v-tab-item>
                      <v-card elevation="0" class="col-md-12">
                        <div class="fs-fix">
                          <!-- <h3 class="mb-2 pa-2">Deskripsi Pelatihan</h3> -->
                          <vue-show-more-text :text="program.program_benefits" :lines="10" :more-text="more"
                            :less-text="less" additional-content-css="font-size:0.875rem !important;"
                            additional-content-expanded-css="font-size:0.875rem !important;"
                            additional-anchor-css="font-size: 0.875rem !important;font-weight:bold;color:#324577!important; text-align: center!important;"
                            @click="change" />
                          <h3 class="pa-2">Kategori</h3>
                          <p class="pa-2 fs-fix">
                            {{ this.studies.title }}
                          </p>

                          <h3 class="pa-2">Lembaga Pengajar</h3>
                          <div class="row">
                            <div class="mx-auto text-center col-md-3 align-self-center">
                              <img :src="header_logo" alt="Logo" style="width: 70%" />
                            </div>
                            <div class="col-md-9">
                              <p class="fs-16 fs-xs-14">
                                Lembaga pelatihan TEFLIN bekerjasama dengan
                                para ahli untuk menghadirkan pelatihan yang
                                mudah dipahami, inovatif dan aplikatif.
                              </p>
                              <p class="mb-0 fs-16 fs-xs-14">
                                TEFLIN berupaya untuk menjadi lembaga pelatihan
                                yang kredibel dan membantu masyarakat Indonesia
                                secara umum memiliki keahlian yang bernilai jual
                                sehingga mencapai kemandirian ekonomi dan
                                peningkatan pendapatan.
                              </p>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <div class="px-3 fs-fix">
                        <h3 class="mb-2 pa-2">Fasilitator</h3>
                        <div class="row">
                          <div v-for="(item, index) in this.program.fasilitator" :key="'A' + index">
                            <div class="col-md-12 ma-auto">
                              <img :src="item.picture.url" class="mx-2 text-center" style="
                                  width: 120px;
                                  height: 120px;
                                  border-radius: 50%;
                                  object-fit: cover;
                                " />
                            </div>
                          </div>
                          <div class="col-md-12" v-for="(item, index) in this.fasilitator" :key="'B' + index">
                            <p class="mb-0 pa-2 fs-fix" v-html="item.description"></p>
                          </div>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card elevation="0" class="pt-5 col-md-12" v-if="formUlasan">
                        <h3 class="ps-3">Rating &amp; Ulasan</h3>
                        <v-rating v-model="rating" background-color="#E0E6ED" color="primary" size="50"></v-rating>
                        <v-col cols="12" md="12" class="">
                          <v-textarea solo name="input-7-4" label="Berikan ulasan Anda" v-model="ulasan"></v-textarea>
                          <v-btn color="primary" class="btn-beli ms-auto rounded-pill" elevation="2" :loading="subSend"
                            @click="send()">
                            Submit
                          </v-btn>
                        </v-col>
                        <!-- <v-divider></v-divider> -->
                      </v-card>
                      <div v-if="detailPublic.total_participants > 0" class="px-4 pt-5">
                        <p class="mb-3 text-h5">
                          Peserta Bergabung
                          <b style="color: #f05326">{{
                            detailPublic.total_participants
                          }}</b>
                        </p>
                      </div>
                      <div v-if="totalData === 0" class="mt-5 text-center col-md-12">
                        <img src="@/assets/images/empty-review.png" width="70" />
                        <p class="text-h6">Belum ada ulasan untuk kelas ini</p>
                      </div>
                      <div v-else class="px-4 pt-5">
                        <div class="mt-5 mb-3 text-center rate col-md-12">
                          <div class="row">
                            <v-card class="px-1 py-4 rounded-lg" outlined elevation="0">
                              <h2 class="font-weight-bold display-2">
                                {{ this.rate.rating }}
                              </h2>
                              <v-rating v-model="this.rate.rating" color="#FFCC18" background-color="#E0E6ED"
                                empty-icon="$ratingFull" half-increments hover small readonly></v-rating>
                              <p class="mb-0 text-muted fs-14 font-weight-bold color-disable">
                                {{ this.rate.count }} Ulasan
                              </p>
                            </v-card>
                            <v-card class="pa-2 col-md-4 col-12" elevation="0" style="width: 150px">
                              <v-list-item-group>
                                <v-list-item class="border-0" style="
                                    min-height: auto !important;
                                    border: none !important;
                                  ">
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>5</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear v-model="persenRate5" color="amber" height="8"
                                      rounded></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{ rate5 }} ulasan
                                    </span>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item class="border-0" style="
                                    min-height: auto !important;
                                    border: none !important;
                                  ">
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>4</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear v-model="persenRate4" color="amber" height="8"
                                      rounded></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{ rate4 }} ulasan
                                    </span>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item class="border-0" style="
                                    min-height: auto !important;
                                    border: none !important;
                                  ">
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>3</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear v-model="persenRate3" color="amber" height="8"
                                      rounded></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{ rate3 }} ulasan
                                    </span>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item class="border-0" style="
                                    min-height: auto !important;
                                    border: none !important;
                                  ">
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>2</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear v-model="persenRate2" color="amber" height="8"
                                      rounded></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{ rate2 }} ulasan
                                    </span>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item class="border-0" style="
                                    min-height: auto !important;
                                    border: none !important;
                                  ">
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>1</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear v-model="persenRate1" color="amber" height="8"
                                      rounded></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{ rate1 }} ulasan
                                    </span>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list-item-group>
                            </v-card>
                          </div>
                        </div>
                        <!-- <div class="col-md-4"></div> -->
                        <v-col md="12">
                          <p class="mb-0 text-h6">Apa Kata Mereka</p>
                          <v-list three-line id="detail-kelas">
                            <template>
                              <v-list-item v-for="(item, index) in this.listReview" :key="'X' + index">
                                <v-list-item-avatar>
                                  <img src="@/assets/images/no-pict.png" />
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    item.member.fullname
                                  }}</v-list-item-title>
                                  <div>
                                    <v-icon color="yellow" small v-for="index in item.star"
                                      :key="'Y' + index">mdi-star</v-icon>
                                  </div>
                                  <v-list-item-subtitle style="display: block !important">{{ item.review
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-list>
                          <v-btn v-if="totalData > 2" color="primary" class="mx-auto text-center btn-beli" text
                            @click="getReviewClass(-1)">
                            Tampilkan lebih banyak
                          </v-btn>
                        </v-col>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-row>
              </template>
            </template>
            <template v-if="contentType === 1">
              <ClassDiscussion :id_topic="id_topic" :id_act="id_act" :topic_title="detailTopic.name"
                :act_title="act.title" :id_program="program.id" title="Diskusi Publik" />
            </template>
            <template v-if="contentType === 2">
              <ClassDiscussionPrivate :id_topic="id_topic" :id_act="id_act" :act="act" :topic="detailTopic"
                :detailClass="detailClass" :id_program="program.id" :trainers="detailClass.program.trainers || []"
                title="Diskusi Private" />
            </template>
          </template>
        </v-col>

        <!-- SECTION ACTIVITY AND TOPIC -->
        <v-slide-x-reverse-transition>
          <v-col v-if="isOpen" cols="12" sm="4" :style="responsiveStyle">
            <v-row v-if="!$vuetify.breakpoint.smAndUp" no-gutters>
              <v-btn class="pl-1 pr-2 mb-2" text color="primary" @click="showActivity = false">
                <v-icon class="mr-2">$prev</v-icon>
                Kembali
              </v-btn>
            </v-row>
            <div class="py-4 mb-3 rounded white">
              <div class="px-4 mb-2">
                <v-row>
                  <v-col cols="12" class="mb-4 pa-0">
                    <v-card elevation="0">
                      <img style="width: 100%; object-fit: cover" :src="cover.url" />
                    </v-card>
                  </v-col>
                </v-row>
                <div class="d-flex align-center">

                  <v-progress-linear class="mr-2" color="primary" background-color="grey lighten-3" height="6" rounded
                    :value="this.detailClass.results.progress"></v-progress-linear>
                  <div class="navy-soft--text">
                    {{ this.detailClass.results.progress }}%
                  </div>
                </div>
                <div class="mb-2 font-weight-black text-body-2" style="color: #333">
                  Jumlah aktivitas selesai
                  ({{ detailClass.results.total_activity_completed }}/{{ detailClass.results.total_activity }}
                  aktivitas)
                </div>
                <v-tabs id="" v-model="tab" background-color="transparent" grow>
                  <v-tab class="text-capitalize primary--text">Aktivitas</v-tab>
                  <v-tab class="text-capitalize primary--text" @click="getDetailClass()">Sertifikat</v-tab>
                </v-tabs>
                <!-- <div class="font-weight-bold">Aktifitas</div> -->
              </div>
              <v-tabs-items v-model="tab" class="setting transparent">
                <v-tab-item>
                  <div>
                    <v-expansion-panels class="overflow-auto custom-panels" style="max-height: 64vh"
                      v-model="topics_active" focusable>
                      <!-- :disabled="this.detailClass.results.progress ===100 && this.scorePost >= this.setMinim.minimum_value" -->
                      <v-expansion-panel class="rounded-0" v-for="(item, index) in topics" :key="index">
                        <v-expansion-panel-header class="px-4" @click="() => {
                          if (!item.is_lock && !item.is_complete) {
                            getDetailTopic(item.id)
                          }
                        }
                          ">
                          <div>
                            <div class="mb-1 font-weight-bold">
                              {{ item.name }}
                            </div>
                            <div class="text-body-2 color-navy-soft">
                              {{ item.activities.length }} Aktivitas
                            </div>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="navy-soft pa-0">
                          <v-list dense class="col-12 pa-0">
                            <v-list-item-group v-model="act_active">
                              <v-divider></v-divider>
                              <v-list-item v-for="(act, i) in item.activities" :key="i" class="grey lighten-5 d-block"
                                @click="onClickActivity(item, act)" :disabled="act.is_lock == true">
                                <v-list-item-content>
                                  <div class="my-1 d-flex">
                                    <v-icon v-if="act.is_complete === true" class="mt-0 mr-1" color="green darken-3"
                                      small>ri-checkbox-circle-fill</v-icon>
                                    <div style="width: 2rem">{{ i + 1 }}.</div>
                                    <div>
                                      <div class="mb-1 font-weight-medium">
                                        {{ act.title }}
                                      </div>
                                      <div class="color-navy-soft text-body-2 d-flex">
                                        <template v-if="act.type == 'menonton_video'">
                                          <span class="mr-2 d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-film-line</v-icon>
                                            Menonton Video
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-time-line</v-icon>
                                            00:{{
                                              act.settings.estimated_duration
                                              | minute
                                            }}:00
                                          </span>
                                        </template>
                                        <template v-else-if="
                                          act.type == 'pre_test' ||
                                          act.type == 'post_test' ||
                                          act.type == 'quiz' ||
                                          act.type == 'exam'
                                        ">
                                          <span class="mr-2 d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-survey-line</v-icon>
                                            Mengerjakan Soal
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{
                                              dateTime(
                                                act.settings.estimated_duration
                                              )
                                            }}
                                          </span>
                                        </template>
                                        <template v-else-if="
                                          act.type == 'praktek_mandiri'
                                        ">
                                          <span class="mr-2 d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-file-download-line</v-icon>
                                            Praktek Mandiri
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{
                                              dateTime(
                                                act.settings.estimated_duration
                                              )
                                            }}
                                          </span>
                                        </template>
                                        <template v-else-if="
                                          act.type ==
                                          'evaluasi_praktek_mandiri'
                                        ">
                                          <span class="mr-2 d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-file-upload-line</v-icon>
                                            Evaluasi Praktek Mandiri
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{
                                              dateTime(
                                                act.settings.estimated_duration
                                              )
                                            }}
                                          </span>
                                        </template>
                                        <template v-else-if="
                                          act.type == 'berdiskusi_daring'
                                        ">
                                          <span class="mr-2 d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-survey-line</v-icon>
                                            Berdiskusi Daring
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{
                                              dateTime(
                                                act.settings.estimated_duration
                                              )
                                            }}
                                          </span>
                                        </template>
                                        <template v-else-if="
                                          act.type == 'berdiskusi_luring'
                                        ">
                                          <span class="mr-2 d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-survey-line</v-icon>
                                            Berdiskusi Luring
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{
                                              dateTime(
                                                act.settings.estimated_duration
                                              )
                                            }}
                                          </span>
                                        </template>
                                        <template v-if="act.type == 'embed_youtube'">
                                          <span class="mr-2 d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-film-line</v-icon>
                                            Video Pembelajaran
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-time-line</v-icon>
                                            {{ dateTime(act.settings.estimated_duration) }}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'evaluasi'">
                                          <span class="mr-2 d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-edit-box-line</v-icon>
                                            Evaluasi
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{
                                              dateTime(
                                                act.settings.estimated_duration
                                              )
                                            }}
                                          </span>
                                        </template>
                                        <template v-else-if="
                                          act.type == 'membaca-e-book'
                                        ">
                                          <span class="mr-2 d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-file-list-line</v-icon>
                                            Membaca E-Book
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="mr-1 navy-soft--text" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{
                                              dateTime(
                                                act.settings.estimated_duration
                                              )
                                            }}
                                          </span>
                                        </template>
                                      </div>
                                    </div>
                                  </div>
                                </v-list-item-content>
                                <v-divider style="
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                  "></v-divider>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <v-divider class="mb-2"></v-divider>
                  <div class="mx-auto col-md-11">
                    <div>
                      <a target="_blank" :href="detailClass.results.certificates.graduation.url">
                        <v-btn color="primary" class="mx-auto text-center btn-beli" block elevation="2"
                          :disabled="!isDisable">
                          Sertifikat Program
                        </v-btn>
                      </a>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
        </v-slide-x-reverse-transition>
      </v-row>
    </v-container>

    <!-- SNACKBAR -->
    <v-snackbar v-model="notification.state" top color="primary" outlined>
      <span v-html="notification.message"></span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" icon v-bind="attrs" @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- DIALOG SCAN -->
    <v-dialog v-model="showScan" persistent max-width="580px" content-class="rounded-xl">
      <v-card class="pa-4">
        <v-card-title class="justify-end px-0 pt-0 d-flex">
          <v-btn fab color="white" @click="showScan = false" small>
            <v-icon color="#90A4AE">ri-close-circle-line</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0 text-center">
          <span class="text-h5">QR Code Kelas </span>
          <div class="pt-0 mx-auto col-md-8">
            <p class="pt-3 mb-0 text-body-1">{{ program.title }}</p>
          </div>
          <vue-qrcode :value="$route.params.id" :options="{ width: 200 }"></vue-qrcode>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG ULASAN -->
    <v-dialog v-model="dialog" persistent max-width="480px">
      <v-card>
        <v-card-title style="position: relative">
          <div class="justify-center d-flex align-center" style="width: 100%">
            <span class="text-h6">Berikan Rating &amp; Ulasan</span>
          </div>
          <v-btn @click="dialog = false" icon style="position: absolute; right: 0; top: 0">
            <v-icon>ri-close-line</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <p class="pt-3 mb-0 text-center text-body-1">
            Selamat kamu telah menyelesaikan semua aktivitas!
          </p>
          <v-card elevation="0" class="px-0">
            <v-rating class="text-center" empty-icon="ri-star-line" full-icon="ri-star-fill" v-model="rating" required
              background-color="#E0E6ED" color="primary" size="50"></v-rating>
            <v-col cols="12" md="12" class="px-0 mb-0">
              <v-textarea solo name="input-7-4" label="Berikan ulasan Anda" v-model="ulasan" required
                hide-details="auto"></v-textarea>
            </v-col>
          </v-card>
          <v-btn color="primary" :loading="subSend" @click="send()" small block class="my-3 me-2"
            :disabled="ulasan === '' || rating === 0">
            Submit
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG UNLOCK ACTIVITY -->
    <v-dialog v-model="activity_unlock" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Perhatian</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <p class="pt-3 mb-0 text-body-1">
            Mohon maaf tidak bisa mengakses aktifitas ini. Silahkan
            menyelesaikan aktifitas sebelumnya.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="subSend" @click="reloadPage()" small class="mb-3 me-2">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG PERINGATAN -->
    <v-dialog v-model="dialog_peringatan" persistent max-width="600px">
      <v-card color="primary" dark>
        <v-card-text> Anda Terindikasi mempercepat video </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog_peringatan = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG SUPPORT BROWSER -->
    <v-dialog v-model="dialog_support" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark class="font-weight-bold text-h5">Perhatian</v-toolbar>
        <v-card-text class="pb-0">
          <div class="pt-4 text-body-1 font-weight-medium">
            Silahkan gunakan browser lain untuk mengakses aktifitas ini.
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog_support = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG QUIZ -->
    <v-dialog v-model="dialog_gameplay" persistent :fullscreen="$vuetify.breakpoint.name == 'xs' ? true : false"
      :max-width="$vuetify.breakpoint.name == 'xs' ? 0 : 800" scrollable>
      <v-card>
        <v-card-text style="height: fit-content" class="ma-0">
          <pop-quiz :type="quiz_data.type" :quizData="quiz_data" @click:close="unlockedActivityVideo"></pop-quiz>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script src="http://www.youtube.com/player_api"></script>
<script>
import { mapGetters, mapState } from "vuex";

import ClassDiscussion from "@/components/class-discussion";
import ClassDiscussionPrivate from "@/components/class-discussion-private";
import videojs from "video.js";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
// import pdf from 'vue-pdf'
// import VideoPlayer from "@/components/VideoPlayer.vue";
import AudioPlayer from "@/views/_inc/media/AudioPlayer.vue";

import vueShowMoreText from "vue-show-more-text";
import VueYoutube from "vue-youtube";
import moment from "moment";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import LockedClass from "./components/LockedClass.vue";

import PopQuiz from "@/components/PopQuiz.vue";
import ActPraktekMandiri from "./act/ActPraktekMandiri";
import ActEvaluasiPraktekMandiri from "./act/ActEvaluasiPraktekMandiri";
import ActBerdiskusiDaring from "./act/ActBerdiskusiDaring";
import ActEvaluasi from "./act/ActEvaluasi";
import ActMembacaEbook from "./act/ActMembacaEbook";
import ActMenontonVideo from "../detailClassV2/act/ActMenontonVideo";
import ActBerdiskusi from "../detailClassV2/act/ActBerdiskusi.vue";

document.oncontextmenu = function () {
  return false;
};
export default {
  name: "detail-class",
  components: {
    ClassDiscussion,
    ClassDiscussionPrivate,
    vueShowMoreText,
    AudioPlayer,
    // VideoPlayer,
    // pdf,
    VuePdfApp,
    VueQrcode,
    LockedClass,

    PopQuiz,
    ActPraktekMandiri,
    ActEvaluasiPraktekMandiri,
    ActBerdiskusiDaring,
    ActEvaluasi,
    ActMembacaEbook,
    ActMenontonVideo,
    ActBerdiskusi,
  },
  filters: {
    minute: (val) => String(val).padStart(2, 0),
    minutesAndSeconds(value) {
      var hours = `${Math.floor(value / 3600)}`.padStart(2, 0);
      var minutes = `${Math.floor(value / 60) % 60}`.padStart(2, 0);
      var seconds = `${value % 60}`.padStart(2, 0);
      return `${hours} : ${minutes} : ${seconds}`;
    },
  },
  data: () => {
    return {
      contentType: 0,

      config: {
        toolbar: {
          toolbarViewerLeft: {
            findbar: true,
            previous: true,
            next: true,
            pageNumber: true,
          },
          toolbarViewerRight: {
            presentationMode: true,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
        errorWrapper: true,
      },
      dialog_peringatan: false,

      video_url: "",
      videoOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: null,
            // type: "application/x-mpegURL",
            type: "video/mp4",
          },
        ],
      },

      headers_nilai: [
        { text: "Jenis Test", value: "name", align: "start", sortable: false },
        {
          text: "Nilai Minimal",
          value: "min_score",
          align: "center",
          sortable: false,
        },
        {
          text: "Hasil Nilai Test",
          value: "score",
          align: "center",
          sortable: false,
        },
        {
          text: "Keterangan",
          value: "result_score",
          align: "center",
          sortable: false,
        },
      ],
      dialog: false,
      tab: null,
      tabs: null,
      loadFirstContent: false,
      loadContent: false,
      content: false,
      detailTopic: {},
      detailClass: {},
      detailPublic: {},
      notification: {},
      act: {},
      cover: {},
      fasilitator: {},
      studies: {},
      id_act: "",
      id_topic: "",
      isLoading: false,
      // result: null,
      review: null,
      showActivity: null,
      activity_loading: null,
      act_active: null,
      topics_active: null,

      image: null,
      file: "",
      loadUpload: false,

      resultNilai: false,
      showQues: true,
      showExplanation: false,

      resultNilaiFalse: false,
      showQuesFalse: true,
      showExplanationFalse: false,

      // pre
      showExplanationPre: false,
      showExplanationPreFalse: false,

      //post
      resultNilaiPost: false,
      showQuesPost: true,
      showExplanationPost: false,

      resultNilaiPostFalse: false,
      showQuesPostFalse: true,
      showExplanationPostFalse: false,

      //exam
      resultNilaiExam: false,
      showQuesExam: true,
      showExplanationExam: false,

      resultNilaiExamFalse: false,
      showQuesExamFalse: true,
      showExplanationExamFalse: false,

      more: "Lihat Selengkapnya",
      less: "Sembunyikan",

      playerVars: {
        controls: 0,
      },
      player: {},
      play: "",
      psr: "peserta",
      kom: "kompetensi",
      typeCertif: "",
      listRandom: null,
      randomQuiz: null,

      subSend: false,

      rating: 0,
      ulasan: "",
      totalData: null,

      //inisialisasi rate
      rate5: null,
      rate4: null,
      rate3: null,
      rate2: null,
      rate1: null,
      persenRate1: null,
      persenRate2: null,
      persenRate3: null,
      persenRate4: null,
      persenRate5: null,

      scorePost: "",
      setMinim: {},
      certifP: {},
      certifL: {},
      generate: {},
      generateP: {},
      rate: {},
      listReview: {},
      id_pro: "",
      timeUpdate: 0,

      formUlasan: false,
      loadCertif: false,

      activity_id_active: "",
      topic_id_active: "",

      // TIMER ACTIVITY
      timer: {},
      totalSeconds: null,
      interval: {},
      timerDialog: "",

      // TIMER QUESTION
      timer_question: {},
      totalSecondsQuestion: null,
      intervalQuestion: {},
      timerDialogQuestion: "",

      showScan: false,
      valueCode: "testing",
      size: 300,
      colorScan: { dark: "#000000ff", light: "#ffffffff" },
      quality: 0.92,

      dialog_support: false,
      showError: false,

      show_direction: false,
      questionAnswer: [],

      activity_unlock: false,
      player: null,

      // GAMEPLAY
      dialog_gameplay: false,
      act_status: false,
      quiz_data: {},
      quizSetting: null,
      act_quiz: {},
      quiz_data: [],

      // liveness
      responseCreateLiveness: "",
      stateCallbackLiveness: "",
      loadingState: false,
      loadingLiveness: false,
      is_first_activity: false,
      is_last_activity: false,
      topic_use_liveness: false,
      topic_liveness_is_success: false,
      activity_use_liveness: false,
      activity_liveness_is_success: false,
    };
  },
  created() {
    this.loadFirstContent = true;
    this.getDetailClass().finally(() => {
      this.loadFirstContent = false;
    });
    setTimeout(() => {
      this.getFormReview(this.detailClass.license, this.id_pro.id);
    }, 2000);

    // window.addEventListener("keydown", function(e) {
    //   if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
    //       e.preventDefault();
    //   }
    // }, false);
  },
  mounted() {},
  computed: {
    ...mapGetters(["user"]),
    ...mapState({
    site_data: state => state.company.site_data
    }),
    header_logo() {
    return this.site_data.logo?.url;
    },
    actType() {
      switch (this.act.type) {
        case "berdiskusi_daring":
          return "Berdiskusi Daring";
        case "berdiskusi_luring":
          return "Berdiskusi Luring";
        case "evaluasi_praktek_mandiri":
          return "Evaluasi Praktek Mandiri";
        case "evaluasi":
          return "Evaluasi";
        case "exam":
          return "Exam";
        case "membaca-e-book":
          return "Membaca E-Book";
        case "menonton_video":
          return "Menonton Video";
        case "embed_youtube":
        return "Video Pembelajaran";
        case "post_test":
          return "Post Test";
        case "post_test":
          return "Post Test";
        case "praktek_mandiri":
          return "Praktek Mandiri";
        case "pre_test":
          return "Pre Test";
        case "pre_test":
          return "Pre Test";
        case "quiz":
          return "Quiz";
        default:
          return "";
      }
    },
    isOpen() {
      return this.$vuetify.breakpoint.smAndUp || this.showActivity;
    },
    isDisable() {
      return (
        this.detailClass.is_complete &&
        Object.keys(this.detailClass.results.certificates.graduation).length > 0 
        &&
        this.detailClass.results.certificates.graduation.hasOwnProperty("url") &&
        this.detailClass.results.certificates.graduation.url.length > 0
      );
      // return this.detailClass.results.progress !== 100 || this.scorePost < this.setMinim.minimum_value;
    },
    isRequired() {
      let required = false;
      if(!this.act.settings.is_skip) {
        if(this.currentQuestion?.test?.timer?.active) {
          // jika timer aktif
          if(this.totalSecondsQuestion !== 0 || this.act.current === this.act.total_question) {
            required = true;
          }
        }else {
          // jika timer tidak aktif
          if(this.currentQuestion.answer === null || 
            this.currentQuestion.answer === undefined || 
            this.currentQuestion.answer.length < 1 || 
            this.act.current === this.act.total_question) {
              required = true;
          }
        }
      }

      return required;
      // return (
      //   this.currentQuestion.answer === null ||
      //   this.currentQuestion.answer === undefined || 
      //   this.currentQuestion.answer.length < 1 ||
      //   this.act.current === this.act.total_question
      // );
      // return this.act.current >= this.act.total_question || this.currentQuestion.answer == null;
    },
    isRequiredExam() {
      let required = false;
      if(!this.act.settings.is_skip) {
        if(this.currentQuestionExam?.timer?.active) {
          // jika timer aktif
          if(this.totalSecondsQuestion !== 0 || this.act.current === this.act.total_question) {
            required = true;
          }
        }else {
          // jika timer tidak aktif
          if(this.currentQuestionExam.answer === null || 
            this.currentQuestionExam.answer === undefined || 
            this.currentQuestionExam.answer.length < 1 || 
            this.act.current === this.act.total_question) {
              required = true;
          }
        }
      }

      return required;
      // return (
      //   this.currentQuestionExam.answer === null ||
      //   this.currentQuestionExam.answer === undefined ||
      //   this.currentQuestionExam.answer.length < 1 ||
      //   this.act.current === this.act.total_question
      // );
      // return this.act.current >= this.act.total_question || this.currentQuestion.answer == null;
    },
    isHiddenButtonNextActivity() {
      if(this.act.is_complete == false && this.act.settings.set_minimum_value.is_set === true) {
        if(this.act.score >= this.act.settings.set_minimum_value.minimum_value) {
          return true
        }
      }else if(this.act.is_complete == false && this.act.settings.set_minimum_value.is_set === false) {
        return true
      }
    },
    result_exam() {
      let graduated = true;
      this.act.section.forEach((item) => {
        if (item.score < item.min_score) {
          graduated = false;
        }
      });
      return graduated;
    },
    responsiveStyle() {
      return this.$vuetify.breakpoint.smAndUp
        ? ""
        : {
            zIndex: 99,
            position: "absolute",
            backgroundColor: "#eff2f7",
            height: "100vh",
            overflow: "auto",
          };
    },
    program() {
      return this.detailClass.program || {};
    },
    topics() {
      return this.detailClass.topics || [];
    },
    questions() {
      try {
        return this.act.list_question.reduce(
          (cur, val) => [...cur, ...val.list_question],
          []
        );
      } catch (error) {
        return [];
      }
    },
    questionsExam() {
      try {
        let result = [];
        // .reduce((cur, val) => [...cur, ...val.list_question], [])
        this.act.list_question_exam.forEach((value) => {
          value.groups.forEach((val) => {
            result = [...result, ...val.list_question];
          });
        });
        // console.log('result',result);
        return result;
      } catch (error) {
        return [];
      }
    },
    questionsExplan() {
      try {
        return this.act.list_question_explan;
      } catch (error) {
        return [];
      }
    },
    currentQuestion() {
      try {
        const index = this.act.current - 1;
        return this.questions[index];
      } catch (error) {
        return {};
      }
    },
    optionQuestion() {
      try {
        const index = this.act.current - 1;
        return this.questions[index].test.options;
      } catch (error) {
        return {};
      }
    },
    currentQuestionExam() {
      try {
        const index = this.act.current - 1;
        return this.questionsExam[index];
        // return this.questionsExam[index].question;
      } catch (error) {
        return {};
      }
    },
    optionQuestionExam() {
      try {
        const index = this.act.current - 1;
        return this.questionsExam[index].options;
      } catch (error) {
        return {};
      }
    },
    currentQuestionExplan() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].test.question;
      } catch (error) {
        return {};
      }
    },
    currentQuestionExamExplan() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].question;
      } catch (error) {
        return {};
      }
    },
    optionQuestionExplan() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].test.options;
      } catch (error) {
        return {};
      }
    },
    answerTextExplan() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].answer[0];
      } catch (error) {
        return {};
      }
    },
    correctTextExplan() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].correct_answer[0];
      } catch (error) {
        return {};
      }
    },
    answerExplan() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].is_answered;
      } catch (error) {
        return {};
      }
    },
    correctAnswer() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].is_correct;
      } catch (error) {
        return {};
      }
    },
    explanation() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].test.explanation.correct;
      } catch (error) {
        return {};
      }
    },
  },
  methods: {
    initPreventUnload() {
      window.addEventListener("beforeunload", this.onBeforeUnload);
      this.$route.meta.prevent_unload = true;
    },
    handleLoaded(instance) {
      // console.log("PSPDFKit has loaded: ", instance);
      // Do something.
    },
    async ended(act) {
      if (!this.act.is_complete) {
        this.act_status = act;

        if (this.act.has_done_this_activity == false) {
          await this.hasDoneActivity();
        }

        if (this.quizSetting != null) {
          this.dialog_gameplay = true;
          this.quiz_data = this.act_quiz;
        } else {
          this.unlockedActivityVideo();
        }
      }
    },
    async hasDoneActivity() {
      await this.axios
        .post(`/users/v1/member/activity/update-has-done`, {
          member_redeem_id: this.detailClass.id,
          topic_id: this.id_topic,
          activity_id: this.id_act,
        })
        .then(() => {})
        .catch(() => {});
    },
    async unlockedActivityVideo() {
      this.dialog_gameplay = false
      this.quiz_data = {}
      this.act_quiz = {}
      this.quizSetting = null
      if(!this.act.is_complete) {
        await this.getUnlock(this.id_topic, this.id_act);

        // check if activity is last activity then  get detail class
        if(!this.act.is_last_activity) {
          this.onClickActivity(this.detailTopic, this.act)
        }
      }
    },
    change() {
      // console.log(showAll);
    },
    handleClickMeetingLink() {
      this.loadUpload = true;
      const data = {
        member_redeem_id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
      };
      this.axios
        .put(`/users/v1/member/activity/update_meeting_is_clicked`, data)
        .then(() => {
          this.getActFalse(this.id_topic, this.act.id);
          this.loadUpload = false;
        })
        .catch(() => {
          this.loadUpload = false;
        });
    },

    async onClickActivity(topic, act) {
      this.id_topic = topic.id;
      this.id_act = act.id;

      this.is_first_activity = act.is_first_activity
      this.is_last_activity = act.is_last_activity

      this.showExplanationExam = false 
      this.showExplanationExamFalse = false

      this.act = act;

      // reset counter repeat audio
      localStorage.setItem("audio_playing", 0)

      if(topic.setting.use_liveness) {
        // ketika topic menggunakan use liveness prakerja
        
        if(topic.liveness_is_success == null) {
          // ketika liveness is succes bernilai null
          // maka harus melakukan liveness terlebih dahulu 

          this.topic_use_liveness = topic.setting.use_liveness
          this.liveness_is_success = topic.liveness_is_success

          // close modal activity
          this.showActivity = false;
          this.timer_stop();
          this.timer_question_stop()
          this.totalSeconds = 0;
          this.totalSecondsQuestion = 0;

          this.act = {
            ...act,
            type: "liveness",
          }

          /**
            * TODO: Hit new API to detect liveness
          */
          await this.getStatePrakerjaLiveness()

          return
        }

        // check if liveless is success in topic is false
        if(topic.liveness_is_success == false) {
          
          this.topic_use_liveness = topic.setting.use_liveness
          this.liveness_is_success = topic.liveness_is_success

          // close modal activity
          this.showActivity = false;
          this.timer_stop();
          this.timer_question_stop()
          this.totalSeconds = 0;
          this.totalSecondsQuestion = 0;

          this.act = {
            ...act,
            type: "liveness",
          }

          /**
            * TODO: Hit new API to detect liveness
          */
          await this.getStatePrakerjaLiveness()

          return
        }

        // ketika liveness is succes bernilai selain null
        // maka bisa membuka aktifitas
        if(act.settings.use_liveness) {
          // ketika aktifitas menggunakan use liveness prakerja

          if(act.liveness_is_success == null) {
            // ketika liveness is succes bernilai selain null
            // maka bisa membuka aktifitas

            this.activity_use_liveness = act.settings.use_liveness 
            this.activity_liveness_is_success = act.liveness_is_success

            // close modal activity
            this.showActivity = false;
            this.timer_stop();
            this.timer_question_stop()
            this.totalSeconds = 0;
            this.totalSecondsQuestion = 0;

            this.act = {
              ...act,
              type: "liveness",
            }

            /**
            * TODO: Hit new API to detect liveness
            */
            await this.getStatePrakerjaLiveness()

          }else {

            // check if liveless is success is false
            if(act.liveness_is_success == false) {

              this.activity_use_liveness = act.settings.use_liveness 
              this.activity_liveness_is_success = act.liveness_is_success

              // close modal activity
              this.showActivity = false;
              this.timer_stop();
              this.timer_question_stop()
              this.totalSeconds = 0;
              this.totalSecondsQuestion = 0;

              this.act = {
                ...act,
                type: "liveness",
              }

              /**
              * TODO: Hit new API to detect liveness
              */
              await this.getStatePrakerjaLiveness()

            }else {
              this.getAct(act.id, topic.id);
              this.getActFalse(topic.id, act.id);
              this.showActivity = false;
              this.timer_stop();
              this.timer_question_stop()
              this.totalSeconds = 0;
              this.totalSecondsQuestion = 0;
            }

          }
        }else {
          this.getAct(act.id, topic.id);
          this.getActFalse(topic.id, act.id);
          this.showActivity = false;
          this.timer_stop();
          this.timer_question_stop()
          this.totalSeconds = 0;
          this.totalSecondsQuestion = 0;
        }
      }else {
        if(act.settings.use_liveness) {
          // ketika aktifitas menggunakan use liveness prakerja

          if(act.liveness_is_success == null) {
            // ketika liveness is succes bernilai selain null
            // maka bisa membuka aktifitas

            this.activity_use_liveness = act.settings.use_liveness 
            this.activity_liveness_is_success = act.liveness_is_success

            // close modal activity
            this.showActivity = false;
              this.timer_stop();
              this.timer_question_stop()
              this.totalSeconds = 0;
              this.totalSecondsQuestion = 0;
            
            this.act = {
              ...act,
              type: "liveness",
            }

            /**
            * TODO: Hit new API to detect liveness
            */
            await this.getStatePrakerjaLiveness()

          }else {
            // check if liveless is success is false
            if(act.liveness_is_success == false) {

              this.activity_use_liveness = act.settings.use_liveness 
              this.activity_liveness_is_success = act.liveness_is_success

              // close modal activity
              this.showActivity = false;
              this.timer_stop();
              this.timer_question_stop()
              this.totalSeconds = 0;
              this.totalSecondsQuestion = 0;

              this.act = {
                ...act,
                type: "liveness",
              }

              /**
              * TODO: Hit new API to detect liveness
              */
              await this.getStatePrakerjaLiveness()

            }else {
              this.getAct(act.id, topic.id);
              this.getActFalse(topic.id, act.id);
              this.showActivity = false;
              this.timer_stop();
              this.timer_question_stop()
              this.totalSeconds = 0;
              this.totalSecondsQuestion = 0;
            }
          }
        }else {
          this.getAct(act.id, topic.id);
          this.getActFalse(topic.id, act.id);
          this.showActivity = false;
          this.timer_stop();
          this.timer_question_stop()
          this.totalSeconds = 0;
          this.totalSecondsQuestion = 0;
        }
      }
    },

    // GET DETAIL TOPIC
    getDetailTopic(id_topic) {
      this.id_topic = id_topic;
      this.loadingState = true
      this.axios.get(`/users/v1/myprogram/detail_topic/${this.$route.params.id}/${id_topic}`)
      .then((response) => {
        let res = response.data.data;

        // console.log('getDetailTopic', res);
        this.detailTopic = res

        this.topic_use_liveness = res.setting.use_liveness 
        this.topic_liveness_is_success = res.liveness_is_success

        if(res.setting.use_liveness) {
          if(res.liveness_is_success == null) {
            // close modal activity
            this.showActivity = false;
            this.timer_stop();
            this.timer_question_stop()
            this.totalSeconds = 0;
            this.totalSecondsQuestion = 0

            // CREATE LIVENESS PRAKERJA
            //this.createLivesnessPrakerja()
            this.act = {
              ...this.act,
              type: "liveness",
            }
            this.getStatePrakerjaLiveness()
            return
          }

          if(res.liveness_is_success == false) {
            // close modal activity
            this.showActivity = false;
            this.timer_stop();
            this.timer_question_stop()
            this.totalSeconds = 0;
            this.totalSecondsQuestion = 0

            this.loadingState = false
            this.act = {
              ...this.act,
              type: "liveness",
            }

            this.getStatePrakerjaLiveness()
            return
          }
        }

        this.loadingState = false
      })
    },

    // GET STATE PRAKERJA LIVENESS
    async getStatePrakerjaLiveness() {
      this.loadingState = true
      await this.axios.post(`/users/v1/member/check-callback`, {
        license: this.detailClass.license,
      })
      .then((response) => {
        let res = response.data.data;
        // console.log('getStatePrakerjaLiveness', res);
        this.stateCallbackLiveness = res.code
        this.loadingState = false
      })
    },

    // CREATE LIVENESS PRAKERJA
    async createLivesnessPrakerja(topic_id, act_id) {
      this.loadingLiveness = true
      await this.axios
      .post(`/users/v1/myprogram/create-liveness`,{ license: this.detailClass.license, topic_id: topic_id, post_test_id: act_id })
      .then((response) => {
        let res = response.data
        this.loadingLiveness = false
        window.open(
          res.data.url,
          "_self"
        )
      })
      .catch((err) => {
        this.responseCreateLiveness = err.response.data.message;
        this.loadingLiveness = false
      });
    },

    getDetailClass() {
      this.loadContent = true;
      this.contentType = 0;
      this.content = false;
      return this.axios
        .get(`/users/v1/myprogram/detail/${this.$route.params.id}`)
        .then((response) => {
          this.content = true;
          this.loadContent = false;
          let res = response.data.data;

          // console.log('getDetailClass', res)

          this.detailClass = res;
          this.fasilitator = res.program.fasilitator;
          this.id_pro = res.program;
          this.cover = res.program.program_information.cover;
          this.studies = res.program.learning_targets.studies;
          this.scorePost = res.results.scores.post_test.final_score;
          this.setMinim = res.results.scores.post_test.set_minimum_value;
          this.certifP = res.results.certificates.participant;
          this.certifL = res.results.certificates.graduation;

          if (res.results.progress === 100) {
            this.detailReview(res.license, this.id_pro.id);
          }
          this.getReviewClass(2);
          this.getDetailPublic(this.id_pro.id);
        });
    },
    getDetailPublic(id) {
      this.axios
        .get(`/users/v1/public/program/detail/${id}`)
        .then((response) => {
          let res = response.data.data;
          this.detailPublic = res;
        });
    },
    detailReview(voucher, id) {
      const data = {
        license: voucher,
        program_id: id,
      };
      this.axios
        .post(`/program_review/v1/detail`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          // this.dialog=false;
          this.formUlasan = false;
        })
        .catch((error) => {
          // console.error(error.response);
          if (this.detailClass.results.progress != 100) {
            // this.dialog = false;
            this.formUlasan = false;
          } else {
            // this.dialog=true;
            this.formUlasan = true;
          }
        });
    },
    getFormReview(voucher, id) {
      const data = {
        license: voucher,
        program_id: id,
      };
      this.axios
        .post(`/program_review/v1/detail`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          this.dialog = false;
        })
        .catch((error) => {
          if (this.detailClass.results.progress != 100) {
            this.dialog = false;
          } else {
            this.dialog = true;
          }
        });
    },
    getReviewClass(value) {
      this.axios
        .get(
          `/program_review/v1/list?program_id=${this.id_pro.id}&sort=created_at&sort_type=desc&is_publish=1&limit=${value}`,
          {
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
          }
        )
        .then((response) => {
          this.content = true;
          this.loadContent = false;
          let res = response.data.data;
          this.listReview = res.list;
          this.rate = res.rating;
          this.totalData = res.total_data;
          if (res.stars[0]) {
            this.rate5 = res.stars[0].star5;
            this.rate4 = res.stars[0].star4;
            this.rate3 = res.stars[0].star3;
            this.rate2 = res.stars[0].star2;
            this.rate1 = res.stars[0].star1;
            // console.log(this.rate5)
            this.persenRate5 = parseInt(
              Math.round((this.rate5 / this.rate.count) * 100)
            );
            this.persenRate4 = parseInt(
              Math.round((this.rate4 / this.rate.count) * 100)
            );
            this.persenRate3 = parseInt(
              Math.round((this.rate3 / this.rate.count) * 100)
            );
            this.persenRate2 = parseInt(
              Math.round((this.rate2 / this.rate.count) * 100)
            );
            this.persenRate1 = parseInt(
              Math.round((this.rate1 / this.rate.count) * 100)
            );
          }
        });
    },
    getAct(act, id) {
      this.id_topic = id;
      this.id_act = act;
      this.isLoading = false;

      this.axios
        .get(
          `/users/v1/myprogram/detail_activity/${this.$route.params.id}/${this.id_topic}/${this.id_act}`
        )
        .then(async (response) => {
          let res = response.data.data;
          this.act = res;

          window.scrollTo(0, 0);

          if(res.start_test_at == null) {
            await this.setStartTest(this.$route.params.id, this.id_topic, this.id_act)
          }

          if (this.act.is_complete) {
            this.resultNilai = true;
            this.abortPreventUnload();
          } else {
            this.showQues = true;
            this.initPreventUnload();
          }

          // this.cheating.face = this.act.violation.multi_face_detection.total;
          // this.cheating.newtab = this.act.violation.open_new_tab.total;
          // this.cheating.voice = this.act.violation.chat_detection.total;
          this.useAntiCurang = true;
          setTimeout(() => {
            console.log("ambilkan gambar");
            this.$refs.kamera.capture();
          }, 3e3);


          this.getDetailClass();
          if (this.act.type == "pre_test") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
            } else {
              this.activity_unlock = true;
            }
            if (this.act.is_complete == true || this.act.repeat_test > 0 || this.act.has_done_this_activity) {
              this.resultNilai = this.resultNilaiFalse = true;
              this.showQues = this.showQuesFalse = false;
            } else {
              this.repeat();
              this.repeatFalse(this.act.type, this.act.settings);
            }
            this.$set(this.act, "current", 1);
            this.activity_loading = true;
            await this.axios
              .get( `/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                if (this.act.settings.is_random_question === true) {
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question", this.listRandom);
                  await this.setQuestion();
                  if (
                    this.act.settings.is_show_timer === true &&
                    this.act.is_complete === false
                  ) {
                    this.timer_start(this.act.type, this.act.settings);
                  }
                } else {
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();
                  if (
                    this.act.settings.is_show_timer === true &&
                    this.act.is_complete === false
                  ) {
                    this.timer_start(this.act.type, this.act.settings);
                  }
                }
              });
            return;
          } else if (this.act.type == "post_test") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
            } else {
              this.activity_unlock = true;
            }
            if (this.act.is_complete == true || this.act.repeat_test > 0 || this.act.has_done_this_activity) {
              this.resultNilaiPost = this.resultNilaiPostFalse = true;
              this.showQuesPost = this.showQuesPostFalse = false;
            } else {
              this.repeatPost();
              this.repeatPostFalse(this.act.type, this.act.settings);
            }
            this.$set(this.act, "current", 1);
            this.activity_loading = true;
            await this.axios
              .get( `/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                if (this.act.settings.is_random_question === true) {
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question", this.listRandom);
                  await this.setQuestion();
                  if (
                    this.act.settings.is_show_timer === true &&
                    this.act.is_complete === false
                  ) {
                    this.timer_start(this.act.type, this.act.settings);
                  }
                } else {
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();
                  if (
                    this.act.settings.is_show_timer === true &&
                    this.act.is_complete === false
                  ) {
                    this.timer_start(this.act.type, this.act.settings);
                  }
                }
              });
            return;
          } else if (this.act.type == "exam") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
            } else {
              this.activity_unlock = true;
            }
            if (this.act.is_complete == true || this.act.repeat_test > 0 || this.act.has_done_this_activity) {
              this.resultNilaiExam = this.resultNilaiExamFalse = true;
              this.showQuesExam = this.showQuesExamFalse = false;
            }

            this.$set(this.act, "current", 1);
            this.activity_loading = true;
            await this.axios
              .get( `/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                if (this.act.settings.is_random_question === true) {
                  let shuffle = [...res.data];
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question_exam", this.listRandom);
                  await this.setQuestionExam();
                  if (
                    this.act.settings.is_show_timer === true &&
                    this.act.score === 0
                  ) {
                    this.timer_start(this.act.type, this.act.settings);
                  }
                } else {
                  this.$set(this.act, "list_question_exam", res.data);
                  await this.setQuestionExam();
                  if (
                    this.act.settings.is_show_timer === true &&
                    this.act.score === 0
                  ) {
                    this.timer_start(this.act.type, this.act.settings);
                  }
                }
              });
            return;
          } else if (this.act.type == "quiz") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
            } else {
              this.activity_unlock = true;
            }

            if (this.act.is_complete == true || this.act.repeat_test > 0 || this.act.has_done_this_activity) {
              this.resultNilai = this.resultNilaiFalse = true;
              this.showQues = this.showQuesFalse = false;
            } else {
              this.repeat();
              this.repeatFalse(this.act.type, this.act.settings);
            }
            this.showExplanation = this.showExplanationFalse = false;
            this.$set(this.act, "current", 1);
            this.activity_loading = true;
            await this.axios
              .get( `/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                if (this.act.settings.is_random_question === true) {
                  let shuffle = [...res.data];
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.randomQuiz = shuffle;
                  this.$set(this.act, "list_question", this.randomQuiz);
                  await this.setQuestion();
                  if (
                    this.act.settings.is_show_timer === true &&
                    this.act.is_complete === false
                  ) {
                    this.timer_start(this.act.type, this.act.settings);
                  }
                } else {
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();
                  if (
                    this.act.settings.is_show_timer === true &&
                    this.act.is_complete === false
                  ) {
                    this.timer_start(this.act.type, this.act.settings);
                  }
                }
              });
            return;
            // }else{
            //   this.activity_unlock=true;
            // }
          } else if (this.act.type == "evaluasi") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
              // this.getUnlockEvaluasi(this.topics[this.topics_active].id, this.act.id)
              if (this.act.is_complete == false) {
                this.getUnlockEvaluasi(this.id_topic, this.id_act);
              }
            } else {
              this.activity_unlock = true;
            }
            // this.changeStatus();
          } else if (this.act.type == "membaca-e-book") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
              if (this.act.is_complete == false) {
                this.changeStatus();
              }
            } else {
              this.activity_unlock = true;
            }
          } else if (this.act.type == "embed_youtube") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false
              if (this.act.is_complete == false) {
                this.changeStatus()
              }
            } else {
              this.activity_unlock = true
            }
          } else if (this.act.type == "menonton_video") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
              // this.changeStatus();

              
              // GET DETAIL GAMEPLAY
              this.quizSetting = this.act.quiz
              this.act_quiz = {
                topic_id: id,
                activity_id: act,
                ...this.act.quiz
              }
              // this.dialog_gameplay = true
              
              if(this.act.has_done_this_activity) {
                // check if score in quiz is null
                // if(this.act.quiz.score == null) {
                //   this.act_quiz.setReview = false
                // }else {
                //   this.act_quiz.setReview = true
                // }
                this.ended(this.act.is_complete);
              }
              // else {
              //   this.act_quiz.setReview = false
              // }

              let vid2 = document.getElementById("videoElement");

              if (navigator.userAgent.indexOf(" UCBrowser/") >= 0) {
                this.showVideo = false;
                this.dialog_support = true;
                this.showError = true;
                let vid = document.getElementById("videoElement");

                // $('*').off('keyup keydown keypress');
                vid.autoplay = false;
                vid.load();
              } else {
                this.showVideo = true;
                this.showError = false;
                this.video_url = this.act.theory.file.url;

                this.$set(
                  this.videoOptions.sources[0],
                  "src",
                  this.act.theory.file.url
                );
                this.updatePositionState();
                let $vm = this;
                document.addEventListener("keydown", function (e) {
                  switch (e.keyCode) {
                    case 37:
                      var vid2 = document.getElementById("videoElement");
                      vid2.pause();
                      vid2.currentTime = 0;
                      break;
                    case 38:
                      alert("up");
                      break;
                    case 39:
                      var vid2 = document.getElementById("videoElement");
                      vid2.pause();
                      vid2.currentTime = 0;
                      break;
                    case 40:
                      alert("down");
                      break;
                  }
                });
                this.video_url = this.act.theory.file.url;
                this.$nextTick(function () {
                  const video = document.querySelector("#videoElement");
                  var supposedCurrentTime = 0;
                  video.addEventListener("timeupdate", function () {
                    if (!video.seeking) {
                      supposedCurrentTime = video.currentTime;
                    }
                  });
                  // prevent user from seeking
                  video.addEventListener("seeking", function () {
                    var delta = video.currentTime - supposedCurrentTime;
                    if (Math.abs(delta) > 0.01) {
                      video.currentTime = supposedCurrentTime;
                    }
                  });
                  video.load();
                });
                vid2.autoplay = true;
                vid2.load();
              }

            } else {
              this.activity_unlock = true;
            }

            // this.getTest();

            // this.$refs.videoPlayer.$forceUpdate();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    updatePositionState() {
      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: "BAKAT",
          artist: "",
          album: "",
          artwork: [
            {
              src: "https://dummyimage.com/96x96",
              sizes: "96x96",
              type: "image/png",
            },
            {
              src: "https://dummyimage.com/128x128",
              sizes: "128x128",
              type: "image/png",
            },
            {
              src: "https://dummyimage.com/192x192",
              sizes: "192x192",
              type: "image/png",
            },
            {
              src: "https://dummyimage.com/256x256",
              sizes: "256x256",
              type: "image/png",
            },
            {
              src: "https://dummyimage.com/384x384",
              sizes: "384x384",
              type: "image/png",
            },
            {
              src: "https://dummyimage.com/512x512",
              sizes: "512x512",
              type: "image/png",
            },
          ],
        });
        navigator.mediaSession.setActionHandler("seekto", function () {
          /* Code excerpted. */
        });
        navigator.mediaSession.setActionHandler("seekbackward", function () {
          /* Code excerpted. */
        });
        navigator.mediaSession.setActionHandler("seekforward", function () {
          /* Code excerpted. */
        });
      }
    },
    getActFalse(topic, act) {
      this.id_topic = topic;
      this.id_act = act;
      // this.isLoading = true;
      this.axios
        .get(
          `/users/v1/myprogram/detail_activity/${this.$route.params.id}/${this.id_topic}/${this.id_act}`
        )
        .then(async (response) => {
          let res = response.data.data;
          this.act = res;
          
          window.scrollTo(0, 0);

          if(res.start_test_at == null) {
            await this.setStartTest(this.$route.params.id, this.id_topic, this.id_act)
          }

          // this.getDetailClass();

          if (this.act.type == "pre_test") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
            } else {
              this.activity_unlock = true;
            }

            if (this.act.is_complete == true || this.act.repeat_test > 0 || this.act.has_done_this_activity) {
              this.resultNilai = this.resultNilaiFalse = true;
              this.showQues = this.showQuesFalse = false;
            }
            // else {
            //   this.repeat();
            //   this.repeatFalse();
            // }
            this.$set(this.act, "current", 1);
            this.activity_loading = true;
            await this.axios
            .get( `/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                if (this.act.settings.is_random_question === true) {
                  let shuffle = [...res.data];
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question", this.listRandom);
                  await this.setQuestion();
                } else {
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();
                }
              });
            return;
          } else if (this.act.type == "post_test") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
            } else {
              this.activity_unlock = true;
            }
            if (this.act.is_complete == true || this.act.repeat_test > 0 || this.act.has_done_this_activity) {
              this.resultNilaiPost = this.resultNilaiPostFalse = true;
              this.showQuesPost = this.showQuesPostFalse = false;
            }
            // else {
            //   this.repeatPost();
            //   this.repeatPostFalse(this.act.type,this.act.settings);
            // }
            this.$set(this.act, "current", 1);
            this.activity_loading = true;
            await this.axios
              .get( `/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                if (this.act.settings.is_random_question === true) {
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question", this.listRandom);
                  await this.setQuestion();
                } else {
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();
                }
              });
            return;
          } else if (this.act.type == "exam") {
            // console.log('exam', this.act);
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
            } else {
              this.activity_unlock = true;
            }
            if (this.act.is_complete == true || this.act.repeat_test > 0 || this.act.has_done_this_activity) {
              this.resultNilaiExam = this.resultNilaiExamFalse = true;
              this.showQuesExam = this.showQuesExamFalse = false;
            }

            this.$set(this.act, "current", 1);
            this.activity_loading = true;
            await this.axios
              .get( `/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                if (this.act.settings.is_random_question === true) {
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question_exam", this.listRandom);
                  // console.log('this.act.list_question_exam', this.act);
                  await this.setQuestionExam();
                } else {
                  this.$set(this.act, "list_question_exam", res.data);
                  // console.log('this.act.list_question_exam false', this.act);
                  await this.setQuestionExam();
                }
              });
            return;
          } else if (this.act.type == "quiz") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
            } else {
              this.activity_unlock = true;
            }
            if (this.act.is_complete == true || this.act.repeat_test > 0 || this.act.has_done_this_activity) {
              this.resultNilai = this.resultNilaiFalse = true;
              this.showQues = this.showQuesFalse = false;
            }
            this.showExplanation = this.showExplanationFalse = false;
            this.$set(this.act, "current", 1);
            this.activity_loading = true;
            await this.axios
              .get( `/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                if (this.act.settings.is_random_question === true) {
                  let shuffle = [...res.data];
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.randomQuiz = shuffle;
                  this.$set(this.act, "list_question", this.randomQuiz);
                  await this.setQuestion();
                } else {
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();
                }
              });
            return;
          } else if (this.act.type == "evaluasi") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
              if (this.act.is_complete == false) {
                this.getUnlockEvaluasi(this.id_topic, this.id_act);
              }
            } else {
              this.activity_unlock = true;
            }
          } else if (this.act.type == "membaca-e-book") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false;
              if (this.act.is_complete == false) {
                this.changeStatus();
              }
            } else {
              this.activity_unlock = true;
            }
          } else if (this.act.type == "embed_youtube") {
            if (this.act.is_lock === false) {
              this.activity_unlock = false
              if (this.act.is_complete == false) {
                this.changeStatus()
              }
            } else {
              this.activity_unlock = true
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    getExplan(answer) {
      this.activity_loading = true;
      this.$set(this.act, "current", 1);
      if (answer == true) {
        this.axios
          .get(
            `/users/v1/myprogram/answer_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`
          )
          .then((res) => res.data)
          .then(async (res) => {
            this.$set(this.act, "list_question_explan", res.data);
            this.setQuestionExplan(answer);
          })
          .catch((error) => {
            let message = error.response
              ? error.response.data.message
              : error.message;
            this.notification = {
              state: true,
              message,
            };
          });
      } else {
        this.axios
          .get(
            `/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`
          )
          .then((res) => res.data)
          .then(async (res) => {
            this.$set(this.act, "list_question_explan", res.data);
            this.setQuestionExplan(answer);
          })
          .catch((error) => {
            let message = error.response
              ? error.response.data.message
              : error.message;
            this.notification = {
              state: true,
              message,
            };
          });
      }
    },
    getExplanExam() {
      this.activity_loading = true;
      this.$set(this.act, "current", 1);
      this.axios
        .get(
          `/users/v1/myprogram/answer_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`
        )
        .then((res) => res.data)
        .then(async (res) => {
          this.$set(this.act, "list_question_explan", res.data);
          this.setQuestionExplanExam();
        })
        .catch((error) => {
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.notification = {
            state: true,
            message,
          };
        });
    },
    setQuestionExplan(answer) {
      this.activity_loading = true;
      this.result = false;
      if (answer == true) {
        // console.log(answer);
        let question_count = 0;
        const question_group = this.act.list_question_explan.map((val) => {
          const a = val.question_id;
          return a;
        });
        const data = {
          id: question_group,
        };
        this.axios
          .post(`/bank/v1/question/public/detail_multiple`, data, {
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
          })
          .then((res) => res.data)
          .then((res) => {
            this.activity_loading = false;
            res.data.forEach((question, index) => {
              this.$set(this.act.list_question_explan[index], "test", question);
              if (++question_count == this.act.list_question_explan.length) {
                this.isLoading = false;
              }
            });
          });
      } else {
        const total_question = this.act.list_question_explan.reduce(
          (cur, val) => {
            return cur + val.list_question.length;
          },
          0
        );
        let question_count = 0;
        const question_group = this.act.list_question_explan.map((val) => {
          const a = val.list_question[0].question_id;
          return a;
        });
        const data = {
          id: question_group,
        };
        this.axios
          .post(`/bank/v1/question/public/detail_multiple`, data, {
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
          })
          .then((res) => res.data)
          .then((res) => {
            this.activity_loading = false;
            res.data.forEach((question, index) => {
              // console.log(question);

              this.$set(this.act.list_question_explan[index], "test", question);
              if (++question_count == total_question) {
                this.isLoading = false;
              }
            });
          });
      }
    },
    setQuestionExplanExam() {
      this.activity_loading = true;
      this.result = false;
      let question_count = 0;
      const question_group = this.act.list_question_explan.map((val) => {
        const a = val.question_id;
        return a;
      });
      const data = {
        id: question_group,
      };
      this.axios
        .post(`/bank/v1/question/public/detail_multiple`, data, {
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
        })
        .then((res) => res.data)
        .then((res) => {
          this.activity_loading = false;
          res.data.forEach((question, index) => {
            this.$set(this.act.list_question_explan[index], "test", question);
            if (++question_count == this.act.list_question_explan.length) {
              this.isLoading = false;
            }
          });
        });
    },
    setQuestion() {
      this.activity_loading = true;
      this.result = false;
      const total_question = this.act.list_question.reduce((cur, val) => {
        return cur + val.list_question.length;
      }, 0);
      let question_count = 0;
      // this.act.list_question.forEach(({ list_question }) => {
      // console.log('list',list_question)
      // list_question.forEach((question, index) => {
      //   this.axios(`/bank/v1/question/public/detail/${question.question_id}`,{
      //     headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }
      //   })
      //     .then((res) => res.data)
      //     .then((res) => {
      //       this.$set(list_question, index, res.data);
      //       if (++question_count == total_question) {
      //         this.isLoading = false;
      //       }
      //     });
      // });

      const question_group = this.act.list_question.map((val) => {
        const a = val.list_question[0].question_id;
        return a;
      });
      const data = {
        id: question_group,
      };
      this.axios
        .post(`/bank/v1/question/public/detail_multiple`, data, {
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
        })
        .then((res) => res.data)
        .then((res) => {
          this.activity_loading = false;
          res.data.forEach((question, index) => {
            this.$set(
              this.act.list_question[index].list_question[0],
              "test",
              question
            );
            if (++question_count == total_question) {
              this.isLoading = false;
            }
          });
        });
      // });
    },
    setQuestionExam() {
      this.activity_loading = true;
      this.result = false;
      let total_question = 0;
      this.act.list_question_exam.forEach((value) => {
        total_question += value.groups.reduce((cur, val) => {
          return cur + val.list_question.length;
        }, 0);
      });

      // this.$set(this.act, "total_question", total_question);

      let question_count = 0;
      this.act.list_question_exam.forEach(({ groups }) => {
        // console.log('groups', groups);
        if (groups.length > 0) {
          // groups.total_question = total_question
          groups.forEach((list_question, index) => {
            // list_question.list_question.forEach((question, index) => {
            // console.log('list', list_question.list_question);
            const question_group = list_question.list_question.map((val) => {
              const a = val.question_id;
              return a;
            });
            const data = {
              id: question_group,
            };
            // this.axios(`/bank/v1/question/public/detail/${question.question_id}`,{
            this.axios
              .post(`/bank/v1/question/public/detail_multiple`, data, {
                headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
              })
              .then((res) => res.data)
              .then((res) => {
                this.activity_loading = false;
                res.data.forEach((question, index) => {
                  // console.log('res', question);
                  this.$set(list_question.list_question, index, question);
                  // console.log('list_question', list_question);
                  if (++question_count == total_question) {
                    this.isLoading = false;
                  }
                });
              });
            // })
          });
        }
      });
    },
    submitReview(setting) {
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.question_id;
          const answer = v.test.options[v.answer]
            ? [v.test.options[v.answer].text]
            : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        question_group,
      };
      // console.log("data", data);
      // console.log("act", act);
      // console.log("topik", topic);

      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          // this.getActFalse(this.topics[this.topics_active].id, this.act.id);
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){
          //   if (score >= minimum ) {
          //     // console.log('score',score)
          //     this.getUnlock(this.topics[this.topics_active].id, this.act.id);
          //   }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id, this.act.id);
          // }

          // this.review = true;

          // hit api has done this activity
          this.hasDoneActivity();

          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban terkirim",
          };
          this.showQues = false;
          this.resultNilai = true;
        })
        .catch((error) => {
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitReviewFalse(setting) {
      // console.log('act',act);
      // console.log('topik',this.topics[this.topics_active].id);
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.question_id;
          const answer = v.test.options[v.answer]
            ? [v.test.options[v.answer].text]
            : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        question_group,
      };

      // console.log("data", data);
      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){
          //   if (score >= minimum ) {
          //     // console.log('score',score)
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }
          // this.getUnlock(this.topics[this.topics_active].id, this.act.id);

          // let score = res.data.total_activity_score;
          // console.log('score',score)

          // if (score >= minimum ) {
          //   console.log('score',score)
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }

          // this.review = true;

          // hit api has done this activity
          this.hasDoneActivity();

          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesFalse = false;
          this.resultNilaiFalse = true;
        })
        .catch((error) => {
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitPost(setting) {
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.question_id;
          const answer = v.test.options[v.answer]
            ? [v.test.options[v.answer].text]
            : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        question_group,
      };
      // console.log("answer", data);
      // console.log("act", act);
      // console.log("topik", topic);

      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.resultNilaiPost = true;
          this.showQuesPost = false;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){
          //   // if (score >= minimum ) {
          //     this.getUnlock(this.topics[this.topics_active].id, this.act.id);
          //   // }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id, this.act.id);
          // }

          // this.review = true;

          // hit api has done this activity
          this.hasDoneActivity();

          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban terkirim",
          };
          this.showQues = false;
          this.resultNilai = true;
        })
        .catch((error) => {
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitExam(setting) {
      // console.log('act : ',setting);
      // console.log('list_question_exam : ', this.act.list_question_exam);
      this.activity_loading = true;
      let data = {};
      const question_section = this.act.list_question_exam.map((value) => {
        const question_group = value.groups.map((val) => {
          const questions = val.list_question.map((v) => {
            const question_id = v.id;
            const answer = v.options[v.answer]
              ? [v.options[v.answer].text]
              : [];
            return {
              question_id,
              answer,
            };
          });
          return {
            group_id: val.group_id,
            questions,
          };
        });

        return {
          section_id: value.id,
          question_group,
        };
      });

      data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        sections: question_section,
        question_group: [],
      };
      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.resultNilaiExam = true;
          this.showQuesExam = false;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   // }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }

          // hit api has done this activity
          this.hasDoneActivity();

          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesExamFalse = false;
          this.resultNilaiExamFalse = true;
          this.timer_stop();
          this.timer_question_stop()
          this.totalSeconds = 0;
          this.totalSecondsQuestion = 0;
        })
        .catch((error) => {
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitExamFalse(setting) {
      // console.log('act : ',setting);
      // console.log('list_question_exam : ', this.act.list_question_exam);
      this.activity_loading = true;
      let data = {};
      const question_section = this.act.list_question_exam.map((value) => {
        const question_group = value.groups.map((val) => {
          const questions = val.list_question.map((v) => {
            const question_id = v.id;
            const answer = v.options[v.answer]
              ? [v.options[v.answer].text]
              : [];
            return {
              question_id,
              answer,
            };
          });
          return {
            group_id: val.group_id,
            questions,
          };
        });

        return {
          section_id: value.id,
          question_group,
        };
      });

      data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        sections: question_section,
        question_group: [],
      };
      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.resultNilaiExamFalse = true;
          this.showQuesExamFalse = false;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   // }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }

          // hit api has done this activity
          this.hasDoneActivity();

          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesExamFalse = false;
          this.resultNilaiExamFalse = true;
          this.timer_stop();
          this.timer_question_stop()
          this.totalSeconds = 0;
          this.totalSecondsQuestion = 0;
        })
        .catch((error) => {
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitPostFalse(setting) {
      // console.log('act : ',setting);
      // console.log('list_question_exam : ', this.act.list_question_exam);
      this.activity_loading = true;

      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.question_id;
          const answer = v.test.options[v.answer]
            ? [v.test.options[v.answer].text]
            : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        question_group,
      };

      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.resultNilaiPostFalse = true;
          this.showQuesPostFalse = false;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){
          //   // if (score >= minimum ) {
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   // }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }
          // this.getUnlock(this.topics[this.topics_active].id, this.act.id);

          // let score = res.data.total_activity_score;
          // console.log('score',score)

          // if (score >= minimum ) {
          //   console.log('score',score)
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }

          // this.review = true;

          // hit api has done this activity
          this.hasDoneActivity();

          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesFalse = false;
          this.resultNilaiFalse = true;
        })
        .catch((error) => {
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitQuiz(setting) {
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.question_id;
          const answer = v.test.options[v.answer]
            ? [v.test.options[v.answer].text]
            : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        question_group,
      };
      // console.log("data", data);

      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          // this.getActFalse(this.topics[this.topics_active].id, this.act.id);
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;

          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){
          //   if (score >= minimum ) {
          //     // console.log('score',score)
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }

          // hit api has done this activity
          this.hasDoneActivity();

          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban terkirim",
          };
          this.showQues = false;
          this.resultNilai = true;

          this.timer_stop();
          this.timer_question_stop()
          this.totalSeconds = 0;
          this.totalSecondsQuestion = 0;
        })
        .catch((error) => {
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitQuizFalse(setting) {
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.question_id;
          const answer = v.test.options[v.answer]
            ? [v.test.options[v.answer].text]
            : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        question_group,
      };

      // console.log("param", data);
      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;
          // // console.log('score',score)
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){
          //   if (score >= minimum ) {
          //     // console.log('score',score)
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }

          // this.review = true;

          // hit api has done this activity
          this.hasDoneActivity();

          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesFalse = false;
          this.resultNilaiFalse = true;

          this.timer_stop();
          this.timer_question_stop()
          this.totalSeconds = 0;
          this.totalSecondsQuestion = 0;
        })
        .catch((error) => {
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    getChange(topic, act, type, setting) {
      this.activity_loading = true;
      const data = {
        // type:false,
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading = false;
          this.resultNilai = false;
          this.showQues = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type, setting);
          }

          if (this.act.type == "exam") {
            this.totalSecondsQuestion = this.currentQuestionExam.timer.duration;
            if(this.currentQuestionExam.timer.active) {
              this.timer_question_start();
            }
          }else {
            this.totalSecondsQuestion = this.currentQuestion.test.timer.duration;
            if(this.currentQuestion.test.timer.active) {
              this.timer_question_start();
            }
          }
        })
        .catch((error) => {
          console.error(error);
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.notification = {
            state: true,
            message,
          };
        });
    },
    getChangeFalse(topic, act, type, setting) {
      this.timer_stop();
      this.timer_question_stop()
      this.activity_loading = true;
      const data = {
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading = false;
          this.resultNilaiFalse = false;
          this.showQuesFalse = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type, setting);
          }

          if (this.act.type == "exam") {
            this.totalSecondsQuestion = this.currentQuestionExam.timer.duration;
            if(this.currentQuestionExam.timer.active) {
              this.timer_question_start();
            }
          }else {
            this.totalSecondsQuestion = this.currentQuestion.test.timer.duration;
            if(this.currentQuestion.test.timer.active) {
              this.timer_question_start();
            }
          }
        })
        .catch((error) => {
          console.error(error);
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.notification = {
            state: true,
            message,
          };
        });
    },
    getChangePost(topic, act, type, setting) {
      this.activity_loading = true;
      const data = {
        // type:false,
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      // console.log(data);
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading = false;
          this.resultNilaiPost = false;
          this.showQuesPost = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type, setting);
          }
        })
        .catch((error) => {
          let errorCode = error.response.status;
          let errorData = error.response.data;

          if (errorCode == 400) {
            this.notification = {
              state: true,
              message: errorData.message,
            };
          }
        });
    },
    getChangePostFalse(topic, act, type, setting) {
      this.activity_loading = true;
      const data = {
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading = false;
          this.resultNilaiPostFalse = false;
          this.showQuesPostFalse = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type, setting);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getChangeExam(topic, act, type, setting) {
      this.activity_loading = true;
      const data = {
        // type:false,
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      // console.log(data);
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading = false;
          this.resultNilaiExam = false;
          this.showQuesExam = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type, setting);
          }

          if (this.act.type == "exam") {
            this.totalSecondsQuestion = this.currentQuestionExam.timer.duration;
            if(this.currentQuestionExam.timer.active) {
              this.timer_question_start();
            }
          }else {
            this.totalSecondsQuestion = this.currentQuestion.test.timer.duration;
            if(this.currentQuestion.test.timer.active) {
              this.timer_question_start();
            }
          }
        })
        .catch((error) => {
          let errorCode = error.response.status;
          let errorData = error.response.data;

          if (errorCode == 400) {
            this.notification = {
              state: true,
              message: errorData.message,
            };
          }
        });
    },
    getChangeExamFalse(topic, act, type, setting) {
      this.activity_loading = true;
      const data = {
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading = false;
          this.resultNilaiExamFalse = false;
          this.showQuesExamFalse = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type, setting);
          }

          if (this.act.type == "exam") {
            this.totalSecondsQuestion = this.currentQuestionExam.timer.duration;
            if(this.currentQuestionExam.timer.active) {
              this.timer_question_start();
            }
          }else {
            this.totalSecondsQuestion = this.currentQuestion.test.timer.duration;
            if(this.currentQuestion.test.timer.active) {
              this.timer_question_start();
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    changeStatus() {
      this.getUnlock(this.id_topic, this.id_act);
    },
    getUnlock(topic, act) {
      let set_next_topic_at = this.detailTopic.next_topic_at;
      let next_topic_at = this.detailTopic.next_topic_at;
      let date_now = new Date();
      let next_topic_date = moment.utc(next_topic_at).format("YYYY-MM-DD HH:mm:ss");
      let date_now_at = moment.utc(date_now).format("YYYY-MM-DD HH:mm:ss");
      let next_topic_date_res = moment.utc(next_topic_at).locale("id").format(
        "DD MMMM YYYY HH:mm:ss"
      );

      // check if next topic is under next topci at
      if (next_topic_at != null && date_now_at < next_topic_date) {
        // Chek if this activity is last in topic
        if (this.act.is_last_activity) {
          return (this.notification = {
            state: true,
            message: `Mohon Maaf, Topik selanjutnya dapat di akses pada <b class="font-weight-bold">${next_topic_date_res}</b>`,
          });
        }
      }

      this.loadUpload = true;
      const data = {
        program_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/unlock`, data)
        .then(async () => {
          this.loadUpload = false;
          this.getDetailClass();
          this.getActFalse(this.id_topic, this.act.id);
        })
        .catch((error) => {
          this.loadUpload = false;
          this.notification = {
            state: true,
            message: error.response.data.message,
          };
          console.error(error);
        });
    },
    getUnlockEvaluasi(topic, act) {
      let set_next_topic_at = this.detailTopic.next_topic_at;
      let next_topic_at = this.detailTopic.next_topic_at;
      let date_now = new Date();
      let next_topic_date = moment.utc(next_topic_at).format("YYYY-MM-DD HH:mm:ss");
      let date_now_at = moment.utc(date_now).format("YYYY-MM-DD HH:mm:ss");
      let next_topic_date_res = moment.utc(next_topic_at).locale("id").format(
        "DD MMMM YYYY HH:mm:ss"
      );

      // check if next topic is under next topci at
      if (next_topic_at != null && date_now_at < next_topic_date) {
        // Chek if this activity is last in topic
        if (this.act.is_last_activity) {
          return (this.notification = {
            state: true,
            message: `Mohon Maaf, Topik selanjutnya dapat di akses pada <b class="font-weight-bold">${next_topic_date_res}</b>`,
          });
        }
      }

      const data = {
        program_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/unlock`, data)
        .then(async () => {
          this.getDetailClass();
          this.getActFalse(this.id_topic, this.act.id);
          setTimeout(() => {
            this.getFormReview(this.detailClass.license, this.id_pro.id);
          }, 3000);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getUnlockUpload() {
      let set_next_topic_at = this.detailTopic.next_topic_at;
      let next_topic_at = this.detailTopic.next_topic_at;
      let date_now = new Date();
      let next_topic_date = moment.utc(next_topic_at).format("YYYY-MM-DD HH:mm:ss");
      let date_now_at = moment.utc(date_now).format("YYYY-MM-DD HH:mm:ss");
      let next_topic_date_res = moment.utc(next_topic_at).locale("id").format(
        "DD MMMM YYYY HH:mm:ss"
      );

      // check if next topic is under next topci at
      if (next_topic_at != null && date_now_at < next_topic_date) {
        // Chek if this activity is last in topic
        if (this.act.is_last_activity) {
          return (this.notification = {
            state: true,
            message: `Mohon Maaf, Topik selanjutnya dapat di akses pada <b class="font-weight-bold">${next_topic_date_res}</b>`,
          });
        }
      }

      const data = {
        program_id: this.$route.params.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
      };
      this.axios
        .post(`/users/v1/member/activity/unlock`, data)
        .then(async (res) => {
          this.notification = {
            state: true,
            message: res.data.message,
          };
          this.getDetailClass();
          this.getActFalse(this.id_topic, this.act.id);
        })
        .catch((error) => {
          console.error(error);
          this.notification = {
            state: true,
            message: error.response.data.message,
          };
        });
    },
    repeat() {
      // if (setting.is_show_timer === true) {
      //   this.timer_start(type,setting);
      // }
      this.resultNilai = false;
      this.showQues = true;
      this.showExplanation = false;
      this.getActFalse(this.id_topic, this.act.id);
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    repeatFalse(type, setting) {
      // if (setting.is_show_timer === true) {
      //   this.timer_start(type,setting);
      // }
      // console.log(type)
      // console.log(setting)
      this.resultNilaiFalse = false;
      this.showQuesFalse = true;
      this.showExplanationFalse = false;
      this.getActFalse(this.id_topic, this.act.id);
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    repeatPost() {
      this.resultNilaiPost = false;
      this.showQuesPost = true;
      this.showExplanationPost = false;
      this.getActFalse(this.id_topic, this.act.id);
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    repeatPostFalse(type, setting) {
      // if (setting.is_show_timer === true) {
      //   this.timer_start(type,setting);
      // }
      this.resultNilaiPostFalse = false;
      this.showQuesPostFalse = true;
      this.showExplanationPostFalse = false;
      this.getActFalse(this.id_topic, this.act.id);
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    question_status(index) {
      if (!this.show_direction) {
        if (!index || !this.act.current) {
          return "bg-accent-default";
        }
        if (index == this.act.current) {
          return "bg-accent-orange";
        }

        if (this.questionsExam[index - 1].answer != undefined) {
          return "bg-accent-green";
        }
      }
      return "bg-accent-default";
    },
    goNumber(number) {
      this.act.current = number;
      

      if (this.act.type == "exam") {
        // stop timer when current question is last question the submit
        if (this.act.current == this.questionsExam.length) {
          this.timer_question_stop();
          // this.submitExamFalse(this.act.settings);
          return 
        }

        // apabila soal saat ini memiliki timer soal dan setting tidak boleh di skip
        if(this.currentQuestionExam.timer.duration > 0 && !this.act.settings.is_skip) {
          this.totalSecondsQuestion = this.currentQuestionExam.timer.duration;
          this.timer_question_start()
        }

        // apabila soal saat ini memiliki timer soal dan setting boleh di skip
        if(this.currentQuestionExam.timer.duration > 0 && !this.act.settings.is_skip) {
          this.totalSecondsQuestion = this.currentQuestionExam.timer.duration;
          this.timer_question_start()
        }
        
      }else{
        // stop timer when current question is last question the submit
        if (this.act.current > this.questions.length) {
          this.timer_question_stop();
          // this.submitQuizFalse(this.act.settings);
          return 
        }

        // apabila soal saat ini memiliki timer soal dan setting tidak boleh di skip
        if(this.currentQuestion.test.timer.duration > 0  && !this.act.settings.is_skip) {
          this.totalSecondsQuestion = this.currentQuestion.test.timer.duration;
          this.timer_question_start()
        }

        // apabila soal saat ini memiliki timer soal dan setting boleh di skip
        if(this.currentQuestion.test.timer.duration > 0  && this.act.settings.is_skip) {
          this.totalSecondsQuestion = this.currentQuestion.test.timer.duration;
          this.timer_question_start()
        }

      }
    },
    timer_question_start() {
      // this.timer_question
      // this.intervalQuestion
      // this.timerDialogQuestion
      this.timer_question_stop();
      this.timer_question = setInterval(() => {
        this.totalSecondsQuestion--;

        if(this.totalSecondsQuestion <= 0) {
          clearInterval(this.timer_question);
          this.totalSecondsQuestion = 0
          
          // apabila soal saat ini lebih kecil dari total soal
          if(this.act.current < this.questions.length) {
            setTimeout(() => {
              this.goNumber(this.act.current + 1);

              // apabila soal memiliki setting tidak bisa di skip
              if(!this.act.settings.is_skip) {
                this.timer_question_start();
              }
            }, 50);
          }
        }
      }, 1000);
    },
    timer_question_stop() {
      clearInterval(this.timer_question);
    },
    timer_start(type, setting) {
      this.timer_stop();
      this.totalSeconds = 0;
      this.totalSeconds = setting.timer * 60;
      this.timer = setInterval(() => {
        this.totalSeconds -= 1;
        if (this.totalSeconds === 0) {
          if (type === "pre_test") {
            this.submitReview(setting);
          } else if (type === "post_test") {
            this.submitPostFalse(setting);
          } else if (type === "exam") {
            this.submitExamFalse(setting);
          } else {
            this.submitQuizFalse(setting);
          }
          this.totalSeconds = 0;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    timer_stop() {
      clearInterval(this.timer);
    },
    redirect(type, setting) {
      this.activity_loading = true;
      this.getChange(this.id_topic, this.act.id, type, setting);
      // this.repeat();
    },
    redirectFalse(type, setting) {
      this.activity_loading = true;
      this.getChangeFalse(this.id_topic, this.act.id, type, setting);
    },
    redirectPost(type, setting) {
      this.activity_loading = true;
      this.getChangePost(this.id_topic, this.act.id, type, setting);
      // this.repeat();
    },
    redirectPostFalse(type, setting) {
      this.activity_loading = true;
      this.getChangePostFalse(this.id_topic, this.act.id, type, setting);
    },
    redirectExam(type, setting) {
      this.activity_loading = true;
      this.getChangeExam(this.id_topic, this.act.id, type, setting);
      // this.repeat();
    },
    redirectExamFalse(type, setting) {
      this.activity_loading = true;
      this.getChangeExamFalse(this.id_topic, this.act.id, type, setting);
    },
    currentDateTime(a) {
      return moment(a).format("L");
    },
    day(a) {
      // moment.lang("de").format('LLL');
      if (a === "sunday") {
        return "Minggu";
      } else if (a === "monday") {
        return "Senin";
      } else if (a === "tuesday") {
        return "Selasa";
      } else if (a === "wednesday") {
        return "Rabu";
      } else if (a === "thursday") {
        return "Kamis";
      } else {
        return "Jumat";
      }
      // return moment(a).lang("id").format("D");
    },
    date(a) {
      return a ? moment(a).locale("en-gb").format("L") : null;
    },
    dateTime(value) {
      var minut = value * 60;
      var hours = `${Math.floor(minut / 3600)}`.padStart(2, 0);
      var minutes = `${Math.floor(minut / 60) % 60}`.padStart(2, 0);
      var seconds = `${minut % 60}`.padStart(2, 0);
      return `${hours}:${minutes}:${seconds}`;
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .output("blob")
        .then((blob) => {
          let formData = new FormData();
          let certif = blob;

          formData.append("file", certif, "Sertifikat.pdf");
          formData.append("source", "upload");
          formData.append("title", "Sertifikat");
        });
    },
    hasGenerated() {
      // console.log($event);
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    ctfPeserta(type) {
      this.typeCertif = type;
      this.$refs.certifPeserta.generatePdf();
    },
    ctfKompetensi() {
      this.$refs.certifKom.generatePdf();
    },
    send() {
      this.subSend = true;
      const data = {
        license: this.detailClass.license,
        program_id: this.program.id,
        review: this.ulasan,
        star: this.rating,
      };
      this.axios
        .post(`/program_review/v1/create`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async () => {
          this.subSend = false;
          this.dialog = false;
          this.getDetailClass();
          // this.getReviewClass();
          this.notification = {
            state: true,
            message: "Ulasan Terkirim",
          };
        })
        .catch((error) => {
          this.subSend = false;
          this.rating = null;
          this.ulasan = "";
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        });
    },
    reloadPage() {
      location.reload();
    },
  },
  watch: {
    review(val) {
      if (val) {
        this.answer = [1, 3];
      } else {
        this.answer = null;
      }
    },
  },
};
</script>

<style>
#frame {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#player .ytp-chrome-top-buttons {
  display: none !important;
}

#player .ytp-pause-overlay-controls-hidden .ytp-pause-overlay {
  display: none !important;
}

iframe {
  width: 100%;
  height: 100%;
}

.videoku .slider {
  display: none !important;
}

.custom-table.v-data-table--fixed-header thead th {
  box-shadow: unset !important;
  color: #828080 !important;
  background: #ebebee !important;
}

.custom-panels {
  border-top: thin solid;
  border-bottom: thin solid;
  border-color: rgba(0, 0, 0, 0.12);
}

.custom-panels .v-expansion-panel:before {
  box-shadow: none;
}

.custom-panels .v-expansion-panel--active+.v-expansion-panel,
.v-expansion-panel--active:not(:first-child) {
  margin-top: unset !important;
}

.custom-panels .v-expansion-panel-content__wrap {
  padding: unset !important;
}

.custom-panels .v-expansion-panel--active+.v-expansion-panel:after,
.custom-panels .v-expansion-panel--active:not(:first-child):after {
  opacity: unset;
}

.options .v-list-item {
  border: thin solid #d3dce6;
}

.options .v-list-item-group .v-list-item--active {
  border-color: currentColor;
}

.btn-img {
  background-color: var(--v-primary-base) !important;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 12px 16px;
  position: relative;
  border-radius: 4px;
}

.drop input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.drop {
  border: 1px dashed #ccc;
  border-radius: 2px;
  height: 60vh;
  width: 100%;
  vertical-align: middle;
}

.img-cover {
  width: 100%;
  object-fit: contain;
  height: 250px;
}

.btn-float {
  position: absolute;
  top: 10%;
  right: 5%;
}

.bg-label {
  background: rgb(245 245 245);
  padding: 8px;
  border-radius: 100%;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

#tagImg img {
  max-width: 100%;
  height: auto;
}

#Iframe-Master-CC-and-Rs {
  max-width: 512px;
  max-height: 100%;
  overflow: hidden;
}

/* inner wrapper: make responsive */
.responsive-wrapper {
  position: relative;
  height: 0;
}

.responsive-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
}

/* padding-bottom = h/w as % -- sets aspect ratio */
/* YouTube video aspect ratio */
.responsive-wrapper-wxh-572x612 {
  padding-bottom: 107%;
}

/* general styles */
/* ============== */
.set-border {
  border: 5px inset #4f4f4f;
}

.set-box-shadow {
  -webkit-box-shadow: 4px 4px 14px #4f4f4f;
  -moz-box-shadow: 4px 4px 14px #4f4f4f;
  box-shadow: 4px 4px 14px #4f4f4f;
}

.set-padding {
  padding: 40px;
}

.set-margin {
  margin: 30px;
}

.center-block-horiz {
  margin-left: auto !important;
  margin-right: auto !important;
}

#secondaryToolbarToggle {
  display: none !important;
}

.media-controls-container .slider {
  display: none !important;
}

#desc-video img {
  width: 100%;
}

#videoku .media-controls-container,
.media-controls-container * {
  background: #000;
}

video::-webkit-media-controls-timeline {
  display: none !important;
}

video::-webkit-media-controls-timeline {
  -webkit-appearance: media-slider;
  display: none !important;
}

video::-internal-media-controls-download-button {
  display: none;
}
</style>
