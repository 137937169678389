<template>
  <v-card class="mb-4" outlined style="width: 100%;">
    <v-card-text class="overflow-hidden pa-0">
      <div :class="`elevation-${elevation} ${color} ${radius}`">
        <v-col class="justify-center px-5 py-3 d-flex align-center">
          <!-- class="pa-2" -->
          <v-btn :loading="loading" v-if="!playing" class="my-auto font-weight-bold" icon text @click.stop="play"
            @mousedown.stop small :disabled="disablingPlayer">
            <v-icon>ri-play-fill</v-icon>
          </v-btn>
          <!-- <v-btn v-else class="my-auto font-weight-bold" icon text @click.stop="pause" @mousedown.stop small>
            <v-icon>ri-pause-line</v-icon>
          </v-btn> -->
          <div class="col pa-0">
            <v-slider class="mt-6 mb-2" hide-details="auto" :color="playing ? active : inactive"
              track-color="color lighten-5" height="15px" min="0" :max="duration" v-model="current" @mousedown="seek"
              @change="seeked" @mousedown.stop @click.stop step="0.01" :disabled="disablePlayer"></v-slider>
            <div class="d-flex">
              <div class="caption primary--text font-weight-bold">{{ getTime(current) }}</div>
              <v-spacer></v-spacer>
              <div class="caption primary--text font-weight-bold">{{ getTime(duration) }}</div>
            </div>
          </div>

          <slot name="append"></slot>
        </v-col>
        <div class="text-left pa-0" v-show="repeat_audio > 0">
          <v-chip depressed small dark label color="green" class="mb-2 ml-4 font-weight-bold ">
            Sisa pemutaran audio sebanyak
            <span class="ml-2 font-weight-bold subtitle-2">{{ repeatAudioRemaining }}x</span>
          </v-chip>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "audio-player",
  props: {
    src: { type: String, required: true },
    repeat_audio: { type: Number, default: 0 },
    controller: { type: Boolean, Default: true },
    color: { type: String, default: "white" },
    rounded: { type: String, default: "md" },
    elevation: { type: String, default: "1" },
    active: { type: String, default: "primary" },
    inactive: { type: String, default: "color" },
    playlist: { type: String, default: null },
  },
  computed: {
    repeatAudioRemaining() {
      return Number(this.repeat_audio) - Number(this.counter_play)
    },
    disablingPlayer() {
      if(this.counter_play >= this.repeat_audio) return true
      else return false
    },
    radius() {
      let rounded = "rounded";

      if (this.rounded !== "md") {
        rounded += `-${this.rounded}`;
      }

      return rounded;
    },
  },
  data() {
    return {
      disablePlayer: false,
      loading: false,
      playing: null,
      paused: null,
      seeking: null,
      current: 0,
      duration: 0,
      player: null,

      counter_play: 0,
    };
  },
  created() {
    this.player = new Audio(this.src);
    this.player.onloadstart = () => {
      this.loading = true;
    };
    this.player.onloadeddata = () => {
      this.playing = false;
      this.paused = true;
      this.seeking = false;
      this.current = 0.0;
      this.duration = this.player.duration;
      this.loading = false;

      if (this.repeat_audio > 0) {
        // reset counter play and auto play
        this.counter_play = 0
        if (localStorage.getItem("audio_playing") != 0) this.counter_play = localStorage.getItem("audio_playing")
        else localStorage.setItem("audio_playing", 0)
        // this.play()

        // check if counter and repeat is same 
        if (this.counter_play >= this.repeat_audio) {
          this.disablePlayer = true
        }
      }
    };
    this.player.onended = () => {
      this.playing = false;
      this.current = 0.0;

      if (this.repeat_audio > 0) {
        if (this.counter_play >= this.repeat_audio) {
          this.disablePlayer = true
        }
      }
    };
    this.player.onseek = () => {
      this.seeking = true;
    };
    this.player.onseeked = this.seeked();
  },
  beforeDestroy() {
    this.player.pause();
  },
  methods: {
    play() {
      this.$emit("play");
      let vm = this;

      vm.player.onplay = function () {
        vm.playing = true;
        vm.paused = false;
        if (vm.current == vm.player.duration.toFixed(2)) vm.current = 0.0;
        vm.player.currentTime = vm.current;
      };
      vm.player.ontimeupdate = function () {
        if (!vm.seeking) {
          vm.current = vm.player.currentTime;
        }
      };
      vm.player.play();

      if (vm.repeat_audio) {
        // plus for counter play 
        vm.counter_play++

        localStorage.setItem("audio_playing", vm.counter_play)
      }
    },
    pause() {
      let vm = this;

      vm.player.onpause = function () {
        vm.playing = false;
        vm.paused = true;
        vm.player.ontimeupdate = null;
      };
      vm.player.pause();
    },
    seek() {
      this.seeking = true;
    },
    seeked(end = 0.0) {
      this.player.currentTime = end;
      this.seeking = false;
    },

    getTime(seconds) {
      const format = (val) => `0${Math.floor(val)}`.slice(-2);
      const minutes = (seconds % 3600) / 60;

      return [minutes, seconds % 60].map(format).join(":");
    },
  },
  watch: {
    src: function () {
      this.player.src = this.src;
    },
  },
};
</script>
<style type="text/css">
.v-slider--horizontal .v-slider__track-container {
  height: 3px;
}
</style>